<template>
    <div class=" appointment-area-three appointment-page ptb-100">
        <div class="container">
            <div class="appointment-here-form">
                <div class="section-title">
                    <span>Call To Action</span>
                    <h2>Make An Appointment</h2>
                </div>

                <form>
                    <div class="row">
                        <div class="col-lg-6 col-sm-6">
                            <div class="form-group">
                                <input type="text" class="form-control" id="Name" placeholder="Enter Your Name"> 
                                <i class="bx bx-user"></i>
                            </div>
                        </div>

                        <div class="col-lg-6 col-sm-6">
                            <div class="form-group">
                                <input type="text" class="form-control" id="Email" placeholder="Enter Your Email">
                                <i class="bx bx-envelope"></i>
                            </div>
                        </div>

                        <div class="col-lg-6 col-sm-6">
                            <div class="form-group">
                                <input type="text" class="form-control" id="Phone" placeholder="Enter Your Phone">
                                <i class="bx bx-mobile-alt"></i>
                            </div>
                        </div>
                        
                        <div class="col-lg-6 col-sm-6">
                            <div class="form-group">
                                <select class="form-select"> 
                                    <option value="">Select Service</option>
                                    <option value="">House Cleaning</option>
                                    <option value="">Apartment Cleaning</option>
                                    <option value="">Industry Cleaning</option>
                                    <option value="">Hospital & Health Care</option>
                                    <option value="">Bathroom Cleaning</option>
                                </select>	
                            </div>
                        </div>

                        <div class="col-lg-6 col-sm-6">
                            <div class="form-group">
                                <div class="input-group date" id="datetimepicker2">
                                    <input type="text" class="form-control" placeholder="Date">
                                    <span class="input-group-addon">
                                    </span>
                                </div>	
                                <i class="bx bx-calendar"></i>
                            </div>
                        </div>

                        <div class="col-lg-6 col-sm-6">
                            <div class="form-group">
                                <select class="form-select">
                                    <option value="">Select Time</option>
                                    <option value="2">09.00 AM To 10.00 AM</option>
                                    <option value="0">10.00 AM To 11.00 AM</option>
                                    <option value="3">11.00 AM To 12.00 PM</option>
                                    <option value="4">12.00 PM To 01.00 PM</option>
                                    <option value="5">01.00 PM To 03.00 PM</option>
                                    <option value="6">04.00 PM To 06.00 PM</option>
                                    <option value="7">07.00 PM To 10.00 PM</option>
                                </select>	
                            </div>
                        </div>
                        
                        <div class="col-12">
                            <button type="submit" class="default-btn">Send Request</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'MakeAnAppointment'
}
</script>