import { createWebHistory, createRouter } from "vue-router";

// All Pages
import HomePageOne from "../components/Pages/HomePageOne";
import HomePageTwo from "../components/Pages/HomePageTwo";
import HomePageThree from "../components/Pages/HomePageThree";
import AboutPage from "../components/Pages/AboutPage";
import PricingPage from "../components/Pages/PricingPage";
import TestimonialsPage from "../components/Pages/TestimonialsPage";
import TeamPage from "../components/Pages/TeamPage";
import ProjectPage from "../components/Pages/ProjectPage";
import ProjectDetailsPage from "../components/Pages/ProjectDetailsPage";
import AppointmentPage from "../components/Pages/AppointmentPage";
import FaqPage from "../components/Pages/FaqPage";
import TermsConditionsPage from "../components/Pages/TermsConditionsPage";
import PrivacyPolicyPage from "../components/Pages/PrivacyPolicyPage";
import LogInPage from "../components/Pages/LogInPage";
import RegisterPage from "../components/Pages/RegisterPage";
import RecoverPasswordPage from "../components/Pages/RecoverPasswordPage";
import ErrorPage from "../components/Pages/ErrorPage";
import ServicesPageOne from "../components/Pages/ServicesPageOne";
import ServicesPageTwo from "../components/Pages/ServicesPageTwo";
import ServicesPageThree from "../components/Pages/ServicesPageThree";
import ServicesDetailsPage from "../components/Pages/ServicesDetailsPage";
import ShopPage from "../components/Pages/ShopPage";
import CartPage from "../components/Pages/CartPage";
import CheckoutPage from "../components/Pages/CheckoutPage";
import ProductDetailsPage from "../components/Pages/ProductDetailsPage";
import BlogGridPage from "../components/Pages/BlogGridPage";
import BlogLeftSidebarPage from "../components/Pages/BlogLeftSidebarPage";
import BlogRightSidebarPage from "../components/Pages/BlogRightSidebarPage";
import BlogDetailsPage from "../components/Pages/BlogDetailsPage";
import ContactPage from "../components/Pages/ContactPage";

const routes = [
  { path: "/", component: HomePageOne },
  { path: "/home-two", component: HomePageTwo },
  { path: "/home-three", component: HomePageThree },
  { path: "/about", component: AboutPage },
  { path: "/pricing", component: PricingPage },
  { path: "/testimonials", component: TestimonialsPage },
  { path: "/team", component: TeamPage },
  { path: "/projects", component: ProjectPage },
  { path: "/project-details", component: ProjectDetailsPage },
  { path: "/appointment", component: AppointmentPage },
  { path: "/faq", component: FaqPage },
  { path: "/terms-conditions", component: TermsConditionsPage },
  { path: "/privacy-policy", component: PrivacyPolicyPage },
  { path: "/log-in", component: LogInPage },
  { path: "/register", component: RegisterPage },
  { path: "/recover-password", component: RecoverPasswordPage },
  { path: "/error", component: ErrorPage },
  { path: "/services", component: ServicesPageOne },
  { path: "/services-two", component: ServicesPageTwo },
  { path: "/services-three", component: ServicesPageThree },
  { path: "/services-details", component: ServicesDetailsPage },
  { path: "/shop", component: ShopPage },
  { path: "/cart", component: CartPage },
  { path: "/checkout", component: CheckoutPage },
  { path: "/product-details", component: ProductDetailsPage },
  { path: "/blog-grid", component: BlogGridPage },
  { path: "/blog-left-sidebar", component: BlogLeftSidebarPage },
  { path: "/blog-right-sidebar", component: BlogRightSidebarPage },
  { path: "/blog-details", component: BlogDetailsPage },
  { path: "/contact", component: ContactPage },
];

const router = createRouter({
  history: createWebHistory(),
  linkExactActiveClass: "active",
  routes,
  scrollBehavior() {
    return { top: 0 };
  },
});

export default router;
