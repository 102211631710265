<template>
    <div>
        <Header />
        <PageTitle />
        <ProjectDetailsContent />
        <Footer />
    </div>
</template>

<script>
import Header from '../Layout/Header'
import PageTitle from '../ProjectDetails/PageTitle'
import ProjectDetailsContent from '../ProjectDetails/ProjectDetailsContent'
import Footer from '../Layout/Footer'

export default {
    name: 'ProjectDetailsPage',
    components: {
        Header,
        PageTitle,
        ProjectDetailsContent,
        Footer,
    }
}
</script>
