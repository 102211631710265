<template>
    <div>
        <div class="nav-area">
            <div :class="['navbar-area', {'is-sticky': isSticky}]">
                <div class="main-nav">
                    <nav class="navbar navbar-expand-lg">
                        <div class="container">
                            <router-link class="navbar-brand" to="/">
                                <img src="../../assets/images/renaissance/logo.png" alt="Reinassance Cleaning Services" width="60">
                            </router-link>
                            <div 
                                class="navbar-toggler"
                                @click="active = !active" :aria-pressed="active ? 'true' : 'false'"
                                v-bind:class="{ 'active': button_active_state }"
                                v-on:click="button_active_state = !button_active_state"
                            >
                                <span class="icon-bar top-bar"></span>
                                <span class="icon-bar middle-bar"></span>
                                <span class="icon-bar bottom-bar"></span>
                            </div>
                            <div class="collapse navbar-collapse" :class="{ show: active }">
                                <ul class="navbar-nav m-auto">
                                    <li class="nav-item">
                                        <router-link to="/" class="nav-link">
                                            Home
                                        </router-link>
                                    </li>

                                    <li class="nav-item">
                                        <router-link to="/about" class="nav-link">
                                            About
                                        </router-link>
                                    </li>

                                    <li class="nav-item">
                                        <router-link to="/services" class="nav-link">
                                            Services
                                        </router-link>
                                    </li>
                                    <li class="nav-item">
                                        <router-link to="/team" class="nav-link">
                                            Team
                                        </router-link>
                                    </li>
                                    <li class="nav-item">
                                        <router-link to="/contact" class="nav-link">
                                            Contact
                                        </router-link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </nav>
                </div>
            </div>
        </div>

        <div 
            class="sidebar-modal"
            v-if="isMenu"
        >
            <div class="sidebar-modal-inner">
                <div 
                    class="close"
                    v-on:click="isMenuMethod(isMenu)"
                >
                    <i class='bx bx-x'></i>
                </div>
                <div class="sidebar-about-area">
                    <div class="title">
                        <h2>About Us</h2>
                        <p>We believe brand interaction is key in communication. Real innovations and a positive customer experience are the heart of successful communication. No fake products and services. The customer is king, their lives and needs are the inspiration.</p>
                    </div>
                </div>

                <div class="sidebar-instagram-feed">
                    <h2>Blog</h2>
                    <ul>
                        <li>
                            <router-link to="/blog-details">
                                <img src="../../assets/images/blog/blog-1.jpg" alt="image">
                            </router-link>
                        </li>
                        <li>
                            <router-link to="/blog-details">
                                <img src="../../assets/images/blog/blog-2.jpg" alt="image">
                            </router-link>
                        </li>
                        <li>
                            <router-link to="/blog-details">
                                <img src="../../assets/images/blog/blog-3.jpg" alt="image">
                            </router-link>
                        </li>
                        <li>
                            <router-link to="/blog-details">
                                <img src="../../assets/images/blog/blog-4.jpg" alt="image">
                            </router-link>
                        </li>
                        <li>
                            <router-link to="/blog-details">
                                <img src="../../assets/images/blog/blog-5.jpg" alt="image">
                            </router-link>
                        </li>
                        <li>
                            <router-link to="/blog-details">
                                <img src="../../assets/images/blog/blog-6.jpg" alt="image">
                            </router-link>
                        </li>
                        <li>
                            <router-link to="/blog-details">
                                <img src="../../assets/images/blog/blog-7.jpg" alt="image">
                            </router-link>
                        </li>
                        <li>
                            <router-link to="/blog-details">
                                <img src="../../assets/images/blog/blog-8.jpg" alt="image">
                            </router-link>
                        </li>
                    </ul>
                </div>

                <div class="sidebar-contact-area">
                    <div class="contact-info">
                        <div class="contact-info-content">
                            <h2>
                                <a href="tel:+61-821-456">+61-821-456</a>
                                <span>OR</span>
                                <a href="mailto:info@renaissancecs.co.za">info@renaissancecs.co.za</a>
                            </h2>

                            <ul class="social">
                                <li>
                                    <a href="https://www.facebook.com/" target="_blank">
                                        <i class="bx bxl-facebook"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.twitter.com/" target="_blank">
                                        <i class="bx bxl-twitter"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.linkedin.com/" target="_blank">
                                        <i class="bx bxl-linkedin"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.youtube.com/" target="_blank">
                                        <i class="bx bxl-youtube"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.instagram.com/" target="_blank">
                                        <i class="bx bxl-instagram"></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Navbar',
    data(){
        return {
            isSticky: false,
            isMenu: false,
            active: false,
            button_active_state: false,
            
        }
    },
    mounted(){
        const that = this
        window.addEventListener('scroll', () => {
            let scrollPos = window.scrollY
            if(scrollPos >= 100){
                that.isSticky = true
            } else {
                that.isSticky = false
            }
        })
    },
    computed: {
    },
    methods: {
        isMenuMethod(isMenu){
            return this.isMenu = !isMenu
        }
    }
}
</script>