<template>
    <div class="services-area pt-100 pb-100">
        <div class="container">
            <div class="section-title">
                <span class="top-title">Service</span>
                <h2>Trusted and Reliable Cleaning Services</h2>
                <p>Reinassance Cleaning Services is committed to providing the highest quality commercial cleaning service available by exceeding the expectations of our clients</p>
            </div>

            <div class="row">
                <div class="col-lg-4 col-sm-6 d-flex" v-for="service in services" :key="service.name">
                    <div class="single-services">
                        <img :src="service.image" :alt="service.name">
                        <div class="services-content">
                            <h3>{{service.name}}<br/></h3>
                            <p>{{service.description}}</p>
                            <span :class="service.icon"></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>	
</template>

<script>

export default {
    name: 'CommercialDisinfectionServices',
    data: () => ({
        services: [
            {
                name: 'Contract cleaning services Long & Short Terms', 
                
                icon: 'flaticon-couch', 
                image: require('../../assets/images/services/Contract-Cleaning_Services_Long_Short_Terms.jpg')
            },
            {
                name: 'Commercial and industrial cleaning', 
                
                icon: 'flaticon-couch', 
                image: require('../../assets/images/services/CommercialAndIndustrialCleaning.jpg')
            },
            {
                name: 'Once-Off Cleaning', 
                
                icon: 'flaticon-couch', 
                image: require('../../assets/images/services/Pre_Occupational_Cleaning.jpg')
            },
            {
                name: 'Pre-Occupational Cleaning', 
                
                icon: 'flaticon-couch', 
                image: require('../../assets/images/services/PreOccupationalCleaning_2.jpg')
            },
            {
                name: 'Hygiene Services', 
                
                icon: 'flaticon-couch', 
                image: require('../../assets/images/services/HygieneServices.jpg')
            },
            {
                name: 'Pest Control Services', 
                
                icon: 'flaticon-couch', 
                image: require('../../assets/images/services/PestControlServices.jpg')
            },
            {
                name: 'Fogging & Disinfection Services', 
                
                icon: 'flaticon-couch', 
                image: require('../../assets/images/services/services-1.jpg')
            },

            {
                name: 'Deep Cleaning of Carpets, Upholstery, Ablutions, Canteens & Floors', 
                
                icon: 'flaticon-couch', 
                image: require('../../assets/images/services/DeepCleaningOfCarpetsUpholsteryAblutionsCanteensFloors.jpg')
            },
            {
                name: 'Window Cleaning', 
                
                icon: 'flaticon-couch', 
                image: require('../../assets/images/services/window_cleaning.jpg')
            }
        ]
    })
}
</script>