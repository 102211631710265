<template>
    <div class="top-header-area">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-sm-6">
                    <ul class="header-content-left">
                        <li>
                            <a href="mailto:info@renaissancecs.co.za">
                                <i class="bx bx-envelope"></i>
                                Email: info@renaissancecs.co.za
                            </a>
                        </li>

                        <li>
                            <a href="tel:+27114840102">
                                <i class="bx bx-phone-call"></i>
                                Call Us: +27 11 484 0102 
                            </a>
                        </li>
                    </ul>
                </div>

                <div class="col-lg-6 col-sm-6">
                    <ul class="header-content-right">
                        <li>
                            Opening Hour: 9:00 am - 5:00 pm
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'TopHeader'
}
</script>