<template>
    <div class="response-time-area pb-100">
        <div class="container">
            <div class="response-wrap">
                <h3>
                    Fast Response Time - Local & Professional-Open 24/7 Call Now at 
                    <a href="tel:+61-821-456">+61-821-456</a>
                </h3>
                <div class="shape">
                    <img src="../../assets/images/shape-1.png" alt="Image">
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'Response'
}
</script>