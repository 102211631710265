<template>
    <div class="about-area ptb-100">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6">
                    <div class="about-content">
                        <span>The Cleaning Doctor</span>
                        <h2>Renaissance Cleaning Services</h2>
                        <p>
                            Renaissance Cleaning Services was established in 1999, we take pride of 23 years of excellent service in the cleaning industry. We are 100% black owned & a level 1 BBBEE.
                        </p>
                        <ul>
                            <li>
                                <i class="flaticon-checked"></i>
                                Contract cleaners
                            </li>
                            <li>
                                <i class="flaticon-checked"></i>
                                Floor care
                            </li>
                            <li>
                                <i class="flaticon-checked"></i>
                                Maintanance carpet cleaning 
                            </li>
                            <li>
                                <i class="flaticon-checked"></i>
                                Disinfecting
                            </li>
                        </ul>
                        <p class="mt-4">
                            Renaissance Cleaning Services is run and managed by  Mr. Matthew Manzini (50% shareholder) & Engela Manzini (50% Shareholder) who took over the reins from their father Mike an award-winning entrepreneur who has over the years been recognized for his Excellency and professionalism collected accolades awarded by in the following category:
                        </p>
                        <p class="block-content">
                            <b>South African Breweries</b><br/>
                            <i class='bx bxs-award' style="color: #04b8e5;"></i> (2001 Small Tenant Enterprise of the Year)
                        </p>
                        <p class="block-content">
                            <b>South African Breweries</b><br/>
                            <i class='bx bxs-award' style="color: #04b8e5;"></i> (2002 Small Tenant Enterprise of the Year)
                        </p>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="about-img">
                        <img src="../../assets/images/renaissance/home/renaissance_team.jpg" alt="Renaissance team.">
                        <div class="about-child">
                            <img src="../../assets/images/renaissance/home/renaissance_in_action.jpg" alt="Renaissance in action.">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'AboutUsContent'
}
</script>