<template>
    <div class="process-area bg-color pt-100 pb-70">
        <div class="container">
            <div class="section-title">
                <span>Process</span>
                <h2>How It Works</h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos tenetur vero deserunt! Magni, laborum. Molestias, quidem mollitia, quae minus vero vel reprehenderit, aliquid alias delectus autem.</p>
            </div>

            <div class="row">
                <div class="col-lg-4 col-sm-6">
                    <div class="single-process">
                        <img src="../../assets/images/process/process-1.png" alt="Image">
                        <h3>Account & Check-In</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>

                        <router-link to="/services-details" class="read-more">
                            Get In Touch
                            <i class="bx bx-plus"></i>
                        </router-link>

                        <span>01</span>
                    </div>
                </div>

                <div class="col-lg-4 col-sm-6">
                    <div class="single-process">
                        <img src="../../assets/images/process/process-2.png" alt="Image">
                        <h3>Choose Category</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>

                        <router-link to="/services-details" class="read-more">
                            Get In Touch
                            <i class="bx bx-plus"></i>
                        </router-link>

                        <span>02</span>
                    </div>
                </div>

                <div class="col-lg-4 col-sm-6 offset-sm-3 offset-lg-0">
                    <div class="single-process">
                        <img src="../../assets/images/process/process-3.png" alt="Image">
                        <h3>Get Clean Property</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>

                        <router-link to="/services-details" class="read-more">
                            Get In Touch
                            <i class="bx bx-plus"></i>
                        </router-link>

                        <span>03</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'HowItWorks'
}
</script>