<template>
    <div class="main-banner-area three">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="banner-text text-center m-auto">
                        <h1 class="wow fadeInUp" data-wow-delay=".3s">Disinfection and Sanitation Services</h1>
                            
                        <p class="wow fadeInUp" data-wow-delay=".6s">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nihil architecto laborum eaque! <br> Deserunt maxime, minus quas molestiae reiciendis esse natus nisi iure.</p>

                        <div class="banner-btn wow fadeInUp" data-wow-delay=".9s">
                            <router-link class="default-btn" to="/contact">
                                Contact Us
                            </router-link>
                            <router-link class="default-btn active" to="/about">
                                Learn More
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'MainBanner'
}
</script>