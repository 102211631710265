<template>
    <section class="blog-details-area ptb-100">
			<div class="container">
				<div class="row">
					<div class="col-lg-8 col-md-12">
						<div class="blog-details-desc">
							<div class="article-image">
								<img src="../../assets/images/blog/blog-details.jpg" alt="image">
							</div>

							<div class="article-content">
								<div class="entry-meta">
									<ul>
										<li><span>Posted On:</span> <a href="#">May 19, 2022</a></li>
										<li><span>Posted By:</span> <a href="#">John Anderson</a></li>
									</ul>
								</div>

								<h3>What Can I Do To Prevent Myself & prevent Disease</h3>

								<p>Quuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quia non numquam eius modi tempora incidunt ut labore et dolore magnam dolor sit, consectetur qui ratione voluptatem sequi nesciunt.</p>

								<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in  sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat labore et dolore magna aliqua consectetur adipisicing elit.</p>

								<blockquote class="flaticon-quote">
									<p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Repellendus aliquid praesentium eveniet illum asperiores, quidem, ipsum voluptatum numquam ducimus nisi exercitationem dolorum facilis Repellendus aliquid praesentium eveniet illum asperiores.</p>
								</blockquote>
								
								<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in  sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat. Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in  sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat consectetur adipisicing.</p>

								<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in  sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat ullamco laboris nisi ut aliquip ex ea commodo laboris nisi.</p>

								<div class="row mb-3">
									<div class="col-lg-4 col-sm-6 mt-4">
										<div class="b-d-s-img">
											<img src="../../assets/images/blog/blog-4.jpg" alt="Image">
										</div>
									</div>
									<div class="col-lg-4 col-sm-6 mt-4">
										<div class="b-d-s-img">
											<img src="../../assets/images/blog/blog-5.jpg" alt="Image">
										</div>
									</div>
									<div class="col-lg-4 col-sm-6 offset-sm-3 offset-lg-0 mt-4">
										<div class="b-d-s-img">
											<img src="../../assets/images/blog/blog-6.jpg" alt="Image">
										</div>
									</div>
								</div>

								<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in  sed quia non numquam. consectetur adipisicing elit, sed</p>

								<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in  sed quia non numquam. consectetur adipisicing elit, sed</p>
							</div>

							<div class="article-footer">
								<div class="article-tags">
									<span><i class='bx bx-share-alt'></i></span>

									<a href="#">Share</a>
								</div>

								<div class="article-share">
									<ul class="social">
										<li>
											<a href="https://www.facebook.com/" target="_blank">
												<i class='bx bxl-facebook'></i>
											</a>
										</li>
										<li>
											<a href="https://www.twitter.com/" target="_blank">
												<i class='bx bxl-twitter'></i>
											</a>
										</li>
										<li>
											<a href="https://www.linkedin.com/" target="_blank">
												<i class='bx bxl-linkedin'></i>
											</a>
										</li>
										<li>
											<a href="https://www.pinterest.com/" target="_blank">
												<i class='bx bxl-pinterest-alt'></i>
											</a>
										</li>
										
									</ul>
								</div>
							</div>

							<div class="post-navigation">
								<div class="navigation-links">
									<div class="nav-previous">
										<a href="#"><i class='bx bx-left-arrow-alt'></i> Prev Post</a>
									</div>

									<div class="nav-next">
										<a href="#">Next Post <i class='bx bx-right-arrow-alt'></i></a>
									</div>
								</div>
							</div>

							<div class="comments-area">
								<h3 class="comments-title">2 Comments:</h3>

								<ol class="comment-list">
									<li class="comment">
										<div class="comment-body">
											<footer class="comment-meta">
												<div class="comment-author vcard">
													<img src="../../assets/images/blog/comment-img-1.jpg" class="avatar" alt="image">
													<b class="fn">John Jones</b>
													<span class="says">says:</span>
												</div>

												<div class="comment-metadata">
													<span>April  24, 2022 at 10:59 am</span>
												</div>
											</footer>

											<div class="comment-content">
												<p>Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type.</p>
											</div>

											<div class="reply">
												<a href="#" class="comment-reply-link">Reply</a>
											</div>
										</div>

										<ol class="children">
											<li class="comment">
												<div class="comment-body">
													<footer class="comment-meta">
														<div class="comment-author vcard">
															<img src="../../assets/images/blog/comment-img-2.jpg" class="avatar" alt="image">
															<b class="fn">Steven Smith</b>
															<span class="says">says:</span>
														</div>
			
														<div class="comment-metadata">
															<span>April  24, 2022 at 10:59 am</span>
														</div>
													</footer>
			
													<div class="comment-content">
														<p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim</p>
													</div>
			
													<div class="reply">
														<a href="#" class="comment-reply-link">Reply</a>
													</div>
												</div>
											</li>
										</ol>
									</li>

									<li class="comment">
										<div class="comment-body">
											<footer class="comment-meta">
												<div class="comment-author vcard">
													<img src="../../assets/images/blog/comment-img-3.jpg" class="avatar" alt="image">
													<b class="fn">John Doe</b>
													<span class="says">says:</span>
												</div>

												<div class="comment-metadata">
													<span>April  24, 2022 at 10:59 am</span>
												</div>
											</footer>

											<div class="comment-content">
												<p>Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type.</p>
											</div>

											<div class="reply">
												<a href="#" class="comment-reply-link">Reply</a>
											</div>
										</div>
									</li>
								</ol>

								<div class="comment-respond">
									<h3 class="comment-reply-title">Leave a Reply</h3>

									<form class="comment-form">
										<div class="comment-notes">
											<span id="email-notes">Your email address will not be published.</span>
											Required fields are marked 
											<span class="required">*</span>
										</div>
										<div class="comment-form-author">
											<label>Name <span class="required">*</span></label>
											<input type="text" class="form-control" id="author" name="author" required="required">
										</div>
										<div class="comment-form-email">
											<label>Email <span class="required">*</span></label>
											<input type="email" class="form-control" id="email" name="email" required="required">
										</div>
										<div class="comment-form-url">
											<label>Website</label>
											<input type="url" class="form-control" id="url" name="url">
										</div>
										<div class="comment-form-comment">
											<label>Comment</label>
											<textarea name="comment" class="form-control" id="comment" cols="45" rows="5" maxlength="65525" required="required"></textarea>
										</div>
										<div class="form-submit">
											<input type="submit" name="submit" id="submit" class="submit" value="Post A Comment">
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>

					<div class="col-lg-4 col-md-12">
						<BlogSidebar />
					</div>
				</div>
			</div>
		</section>
</template>

<script>
import BlogSidebar from '../Common/BlogSidebar'

export default {
    name: 'BlogDetailsContent',
    components: {
        BlogSidebar
    }
}
</script>