<template>
    <div class="col-lg-4 col-sm-6">
        <div class="single-product">
            <div class="product-img">
                <router-link to="/product-details">
                    <img :src="item.image" alt="image">
                </router-link>

                <a 
                    href="javascript:void(0)"
                    @click="addToCart(item)"
                    class="default-btn"
                >
                    Add To Cart
                </a>
            </div>
            <div class="product-content">
                <h3>
                    <router-link to="/product-details">
                        {{item.name}}
                    </router-link>
                </h3>
                <ul>
                    <li>${{item.price}}</li>
                    <li><del>${{item.oldPrice}}</del></li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ProductsItem',
    props: ['item'],
    methods: {
        addToCart(item) {
            this.$toasted.show('Added to cart', {
                icons: 'shopping_cart',
                theme: "bubble", 
                position: "top-right", 
                duration : 4000
            });
            if(this.size !== '') {
                this.$store.commit({
                    type: 'addToCart',
                    id: item.id,
                    name: item.name,
                    price: item.price,
                    oldPrice: item.oldPrice,
                    image: item.image,
                    productQuantity: 1,
                    totalPrice: item.price,
                })
            }
        }
    }
}
</script>