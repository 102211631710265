<template>
    <div class="process-area pb-70">
        <div class="container">
            <div class="section-title">
                <span>Process</span>
                <h2>How It Works</h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos tenetur vero deserunt! Magni, laborum. Molestias, quidem mollitia, quae minus vero vel reprehenderit, aliquid alias delectus autem.</p>
            </div>

            <div class="row">
                <div class="col-lg-3 col-sm-6">
                    <div class="single-process-three">
                        <img src="../../assets/images/process/process-4.jpg" alt="Image">
                        <h3>Create Your Account</h3>
                        <p>Procced Your Decision</p>

                        <span>01</span>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6">
                    <div class="single-process-three">
                        <img src="../../assets/images/process/process-5.jpg" alt="Image">
                        <h3>Track Location</h3>
                        <p>Procced Your Decision</p>

                        <span>02</span>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6">
                    <div class="single-process-three">
                        <img src="../../assets/images/process/process-6.jpg" alt="Image">
                        <h3>Get Clean & Fresh</h3>
                        <p>Procced Your Decision</p>

                        <span>03</span>
                    </div>
                </div>
                
                <div class="col-lg-3 col-sm-6">
                    <div class="single-process-three">
                        <img src="../../assets/images/process/process-7.jpg" alt="Image">
                        <h3>Safe From Germs</h3>
                        <p>Procced Your Decision</p>

                        <span>03</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'HowItWorks'
}
</script>