<template>
    <div>
        <Header />
        <PageTitle />
        <OurPricingPlan />
        <HowItWorks />
        <Footer />
    </div>
</template>

<script>
import Header from '../Layout/Header'
import PageTitle from '../Pricing/PageTitle'
import OurPricingPlan from '../Pricing/OurPricingPlan'
import HowItWorks from '../Common/HowItWorks'
import Footer from '../Layout/Footer'

export default {
    name: 'PricingPage',
    components: {
        Header,
        PageTitle,
        OurPricingPlan,
        HowItWorks,
        Footer,
    }
}
</script>
