<template>
    <div>
        <Header />
        <MainBanner />
        <Response />
        <AboutUsContent />
        <CommercialDisinfectionServices />
        <ProtectYourselfFromVirus />
        <HowItWorks />
        <PopularProducts />
        <VirtualDisnifectionServices />
        <Faq />
        <MakeAnAppointment />
        <OurLatestNews />
        <Footer />
    </div>
</template>

<script>
import Header from '../Layout/Header'
import MainBanner from '../HomeTwo/MainBanner'
import Response from '../HomeTwo/Response'
import AboutUsContent from '../Common/AboutUsContent'
import CommercialDisinfectionServices from '../HomeTwo/CommercialDisinfectionServices'
import ProtectYourselfFromVirus from '../HomeTwo/ProtectYourselfFromVirus'
import HowItWorks from '../Common/HowItWorks'
import PopularProducts from '../HomeTwo/PopularProducts'
import VirtualDisnifectionServices from '../HomeTwo/VirtualDisnifectionServices'
import Faq from '../HomeTwo/Faq'
import MakeAnAppointment from "../Common/MakeAnAppointment"
import OurLatestNews from '../Common/OurLatestNews'
import Footer from '../Layout/Footer'

export default {
    name: 'HomePageTwo',
    components: {
        Header,
        MainBanner,
        Response,
        AboutUsContent,
        CommercialDisinfectionServices,
        ProtectYourselfFromVirus,
        HowItWorks,
        PopularProducts,
        VirtualDisnifectionServices,
        Faq,
        MakeAnAppointment,
        OurLatestNews,
        Footer,
    }
}
</script>
