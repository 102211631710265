<template>
    <section class="blog-details-area ptb-100">
			<div class="container">
				<div class="row">
					<div class="col-lg-8 col-md-12">
						<div class="row">
							<div class="col-lg-6 col-md-6">
								<div class="single-blog">
									<router-link to="/blog-details">
										<img src="../../assets/images/blog/blog-1.jpg" alt="Image">
									</router-link>
								
									<div class="blog-content">
										<ul>
											<li>
												<router-link to="/blog-right-sidebar">Admin</router-link>
											</li>
											<li>
												17 / 05 / 2022
											</li>
										</ul>
		
										<router-link to="/blog-details">
											<h3>Corona Virus Infection Prevention</h3>
										</router-link>
										<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt amet adipiscing elit ipsum dolor sit amet.</p>
		
										<router-link to="/blog-details" class="read-more">
											Read More
											<i class="bx bx-plus"></i>
										</router-link>
									</div>
								</div>
							</div>
		
							<div class="col-lg-6 col-md-6">
								<div class="single-blog">
									<router-link to="/blog-details">
										<img src="../../assets/images/blog/blog-2.jpg" alt="Image">
									</router-link>
									
									<div class="blog-content">
										<ul>
											<li>
												<router-link to="/blog-right-sidebar">Admin</router-link>
											</li>
											<li>
												18 / 05 / 2022
											</li>
										</ul>
		
										<router-link to="/blog-details">
											<h3>Superior Clean Through Our Trained</h3>
										</router-link>
										<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt amet adipiscing elit ipsum dolor sit amet.</p>
		
										<router-link to="/blog-details" class="read-more">
											Read More
											<i class="bx bx-plus"></i>
										</router-link>
									</div>
								</div>
							</div>
		
							<div class="col-lg-6 col-md-6">
								<div class="single-blog">
									<router-link to="/blog-details">
										<img src="../../assets/images/blog/blog-3.jpg" alt="Image">
									</router-link>
									
									<div class="blog-content">
										<ul>
											<li>
												<router-link to="/blog-right-sidebar">Admin</router-link>
											</li>
											<li>
												19 / 05 / 2022
											</li>
										</ul>
									
										<router-link to="/blog-details">
											<h3>How To Clean And Spread Disinfect</h3>
										</router-link>
										<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt amet adipiscing elit ipsum dolor sit amet.</p>
		
										<router-link to="/blog-details" class="read-more">
											Read More
											<i class="bx bx-plus"></i>
										</router-link>
									</div>
								</div>
							</div>
		
							<div class="col-lg-6 col-md-6">
								<div class="single-blog">
									<router-link to="/blog-details">
										<img src="../../assets/images/blog/blog-3.jpg" alt="Image">
									</router-link>
									
									<div class="blog-content">
										<ul>
											<li>
												<router-link to="/blog-right-sidebar">Admin</router-link>
											</li>
											<li>
												19 / 05 / 2022
											</li>
										</ul>
									
										<router-link to="/blog-details">
											<h3>250+ Medical Tips We just had to</h3>
										</router-link>
										<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt amet adipiscing elit ipsum dolor sit amet.</p>
		
										<router-link to="/blog-details" class="read-more">
											Read More
											<i class="bx bx-plus"></i>
										</router-link>
									</div>
								</div>
							</div>
							<div class="col-lg-6 col-md-6">
								<div class="single-blog">
									<router-link to="/blog-details">
										<img src="../../assets/images/blog/blog-5.jpg" alt="Image">
									</router-link>
									
									<div class="blog-content">
										<ul>
											<li>
												<router-link to="/blog-right-sidebar">Admin</router-link>
											</li>
											<li>
												12 / 05 / 2022
											</li>
										</ul>
									
										<router-link to="/blog-details">
											<h3>CCU For Emergency Services & Medical support</h3>
										</router-link>
										<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt amet adipiscing elit ipsum dolor sit amet.</p>
		
										<router-link to="/blog-details" class="read-more">
											Read More
											<i class="bx bx-plus"></i>
										</router-link>
									</div>
								</div>
							</div>
							
							<div class="col-lg-6 col-md-6">
								<div class="single-blog">
									<router-link to="/blog-details">
										<img src="../../assets/images/blog/blog-6.jpg" alt="Image">
									</router-link>
									
									<div class="blog-content">
										<ul>
											<li>
												<router-link to="/blog-right-sidebar">Admin</router-link>
											</li>
											<li>
												22 / 05 / 2022
											</li>
										</ul>
									
										<router-link to="/blog-details">
											<h3>What Can I Do To Prevent Myself & prevent Disease</h3>
										</router-link>
										<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt amet adipiscing elit ipsum dolor sit amet.</p>
		
										<router-link to="/blog-details" class="read-more">
											Read More
											<i class="bx bx-plus"></i>
										</router-link>
									</div>
								</div>
							</div>
		
							<div class="col-lg-12">
								<div class="page-navigation-area">
									<nav aria-label="Page navigation example text-center">
										<ul class="pagination">
											<li class="page-item">
												<a class="page-link page-links" href="#">
													<i class='bx bx-chevrons-left'></i>
												</a>
											</li>
											<li class="page-item active">
												<a class="page-link" href="#">1</a>
											</li>
											<li class="page-item">
												<a class="page-link" href="#">2</a>
											</li>
											<li class="page-item">
												<a class="page-link" href="#">3</a>
											</li>
											<li class="page-item">
												<a class="page-link" href="#">
													<i class='bx bx-chevrons-right'></i>
												</a>
											</li>
										</ul>
									</nav>
								</div>
							</div>
						</div>
					</div>

					<div class="col-lg-4 col-md-12">
						<BlogSidebar />
					</div>
				</div>
			</div>
		</section>
</template>

<script>
import BlogSidebar from '../Common/BlogSidebar'

export default {
    name: 'BlogRightSidebarContent',
    components: {
        BlogSidebar
    }
}
</script>