<template>
    <div>
        <Header />
        <PageTitle /> 
        <PrivacyPolicyContent />
        <Footer />
    </div>
</template>

<script>
import Header from '../Layout/Header'
import PageTitle from '../PrivacyPolicy/PageTitle'
import PrivacyPolicyContent from '../PrivacyPolicy/PrivacyPolicyContent'
import Footer from '../Layout/Footer'

export default {
    name: 'PrivacyPolicyPage',
    components: {
        Header,
        PageTitle,
        PrivacyPolicyContent,
        Footer,
    }
}
</script>