<template>
    <div>
        <footer class="footer-top-area f-bg pt-100 pb-70">
            <div class="container">
                <div class="row">
                    <div class="col-lg-3 col-md-6">
                        <div class="single-widget">
                            <router-link to="/">
                                <img src="../../assets/images/renaissance/logo.png" alt="Reinassance Cleaning Services" width="60">
                            </router-link>

                            <p>
                                Renaissance Cleaning Services was established in 1999, we take pride of 23 years of excellent service in the cleaning industry. We are 100% black owned & a level 1 BBBEE.
                            </p>

                            <div class="social-area">
                                <ul>
                                    <li>
                                        <a href="https://www.facebook.com/" target="_blank">
                                            <i class="bx bxl-facebook"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.twitter.com/" target="_blank">
                                            <i class="bx bxl-twitter"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.linkedin.com/" target="_blank">
                                            <i class="bx bxl-linkedin"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.youtube.com/" target="_blank">
                                            <i class="bx bxl-youtube"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.instagram.com/" target="_blank">
                                            <i class="bx bxl-instagram"></i>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    
                    <div class="col-lg-3 col-md-6">
                        <div class="single-widget">
                            <h3>Our Services</h3>

                            <ul>
                                <li>
                                    
                                        <i class="bx bx-chevrons-right"></i>
                                        Window Cleaning
                                </li>
                                <li>
                                        <i class="bx bx-chevrons-right"></i>
                                        Domestic Cleaning
                                </li>
                                <li>
                                        <i class="bx bx-chevrons-right"></i>
                                        Disinfection
                                </li>
                                <li>
                                        <i class="bx bx-chevrons-right"></i>
                                        Office Cleaning
                                </li>
                                <li>
                                        <i class="bx bx-chevrons-right"></i>
                                        House Cleaning
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div class="col-lg-3 col-md-6">
                        <div class="single-widget">
                            <h3>Quick Links</h3>

                            <ul>
                                <li>
                                    <router-link to="/about">
                                        <i class="bx bx-chevrons-right"></i>
                                        About
                                    </router-link>
                                </li>
                                <li>
                                    <router-link to="/services">
                                        <i class="bx bx-chevrons-right"></i>
                                        Services
                                    </router-link>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div class="col-lg-3 col-md-6">
                        <div class="single-widget contact">
                            <h3>Get In Touch</h3>

                            <ul>
                                <li class="pl-0">
                                    <a href="tel:Phone:+ 27114840102">
                                        <i class="bx bx-phone-call"></i>
                                        <span>Hotline:</span> 
                                        Phone: +27 11 484 0102 
                                    </a>
                                </li>
                                
                                <li class="pl-0">
                                    <a href="mailto:info@renaissancecs.co.za">
                                        <i class="bx bx-envelope"></i>
                                        <span>Email:</span> 
                                        info@renaissancecs.co.za
                                    </a>
                                </li>
                                
                                <li>
                                    <i class="bx bx-location-plus"></i>
                                    <span>Address:</span> 
                                    71A Main Road, Eastleigh, Edenvale, 1609 
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </footer>

        <div class="footer-bottom-area">
            <div class="container">
                <div class="copy-right">
                    <p>&copy; {{currentYear}} Renaissance Cleaning Services. All Rights Reserved.</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Footer',
    data() {
        return {
            currentYear: new Date().getFullYear(),
        };
    }
}
</script>