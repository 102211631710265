<template>
    <div>
        <Header />
        <MainBanner />
        <LocationSearch />
        <YourComfortDependsOnUs />
        <AboutUsContent />
        <CommercialDisinfectionServices />
        <HowItWorks />
        <OurPricingPlan />
        <OurPopularProducts class="pt-100" />
        <VirtualDisnifectionServices />
        <WhatOurClientsSay />
        <MakeAnAppointment />
        <OurLatestNews />
        <SaveMoneySaveTime />
        <Footer />
    </div>
</template>

<script>
import Header from '../Layout/Header'
import MainBanner from '../HomeThree/MainBanner'
import LocationSearch from '../HomeThree/LocationSearch'
import YourComfortDependsOnUs from '../HomeThree/YourComfortDependsOnUs'
import AboutUsContent from '../Common/AboutUsContent'
import CommercialDisinfectionServices from '../HomeThree/CommercialDisinfectionServices'
import HowItWorks from '../HomeThree/HowItWorks'
import OurPricingPlan from '../HomeThree/OurPricingPlan'
import OurPopularProducts from '../Common/OurPopularProducts'
import VirtualDisnifectionServices from '../HomeThree/VirtualDisnifectionServices'
import WhatOurClientsSay from '../HomeThree/WhatOurClientsSay'
import MakeAnAppointment from '../HomeThree/MakeAnAppointment'
import OurLatestNews from '../Common/OurLatestNews'
import SaveMoneySaveTime from '../HomeThree/SaveMoneySaveTime'
import Footer from '../Layout/Footer'

export default {
    name: 'HomePageThree',
    components: {
        Header,
        MainBanner,
        LocationSearch,
        YourComfortDependsOnUs,
        AboutUsContent,
        CommercialDisinfectionServices,
        HowItWorks,
        OurPricingPlan,
        OurPopularProducts,
        VirtualDisnifectionServices,
        WhatOurClientsSay,
        MakeAnAppointment,
        OurLatestNews,
        SaveMoneySaveTime,
        Footer,
    }
}
</script>
