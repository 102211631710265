<template>
    <div class="project-area project-page ptb-100">
        <div class="container-fluid">
            <div class="section-title">
                <span>Projects</span>
                <h2>Our Recent Projects</h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos tenetur vero deserunt! Magni, laborum. Molestias, quidem mollitia, quae minus vero vel reprehenderit, aliquid alias delectus autem.</p>
            </div>

            <div class="row">
                <div class="col-lg-4 col-sm-6">
                    <div class="single-project">
                        <img src="../../assets/images/projects/project-1.jpg" alt="Image">
                        <div class="project-content">
                            <h3>
                                <router-link to="/project-details">Window Cleaning</router-link>
                            </h3>
                            <span>Office</span>
                            <router-link to="/project-details">
                                <i class='bx bx-chevron-right'></i>
                            </router-link>
                        </div>
                    </div>
                </div>
            
                <div class="col-lg-4 col-sm-6">
                    <div class="single-project">
                        <img src="../../assets/images/projects/project-2.jpg" alt="Image">
                        <div class="project-content">
                            <h3>
                                <router-link to="/project-details">Kitchen Cleaning</router-link>
                            </h3>
                            <span>Home</span>
                            <router-link to="/project-details">
                                <i class='bx bx-chevron-right'></i>
                            </router-link>
                        </div>
                    </div>
                </div>
                
                <div class="col-lg-4 col-sm-6">
                    <div class="single-project">
                        <img src="../../assets/images/projects/project-3.jpg" alt="Image">
                        <div class="project-content">
                            <h3>
                                <router-link to="/project-details">Commercial cleaning</router-link>
                            </h3>
                            <span>Office Space</span>
                            <router-link to="/project-details">
                                <i class='bx bx-chevron-right'></i>
                            </router-link>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-sm-6">
                    <div class="single-project">
                        <img src="../../assets/images/projects/project-4.jpg" alt="Image">
                        <div class="project-content">
                            <h3>
                                <router-link to="/project-details">Hand Sanitizer</router-link>
                            </h3>
                            <span>Self Protect</span>
                            <router-link to="/project-details">
                                <i class='bx bx-chevron-right'></i>
                            </router-link>
                        </div>
                    </div>
                </div>
            
                <div class="col-lg-4 col-sm-6">
                    <div class="single-project">
                        <img src="../../assets/images/projects/project-5.jpg" alt="Image">
                        <div class="project-content">
                            <h3>
                                <router-link to="/project-details">Floor Cleaning</router-link>
                            </h3>
                            <span>Hospital</span>
                            <router-link to="/project-details">
                                <i class='bx bx-chevron-right'></i>
                            </router-link>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-sm-6">
                    <div class="single-project">
                        <img src="../../assets/images/projects/project-6.jpg" alt="Image">
                        <div class="project-content">
                            <h3>
                                <router-link to="/project-details">House Cleaning</router-link>
                            </h3>
                            <span>House</span>
                            <router-link to="/project-details">
                                <i class='bx bx-chevron-right'></i>
                            </router-link>
                        </div>
                    </div>
                </div>

                <div class="col-lg-12">
                    <div class="page-navigation-area">
                        <nav aria-label="Page navigation example text-center">
                            <ul class="pagination">
                                <li class="page-item">
                                    <a class="page-link page-links" href="#">
                                        <i class='bx bx-chevrons-left'></i>
                                    </a>
                                </li>
                                <li class="page-item active">
                                    <a class="page-link" href="#">1</a>
                                </li>
                                <li class="page-item">
                                    <a class="page-link" href="#">2</a>
                                </li>
                                <li class="page-item">
                                    <a class="page-link" href="#">3</a>
                                </li>
                                <li class="page-item">
                                    <a class="page-link" href="#">
                                        <i class='bx bx-chevrons-right'></i>
                                    </a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'OurRecentProjects'
}
</script>