<template>
  <div>
    <Header />
    <PageTitle />
    <ContactContent />
    <Footer />
  </div>
</template>

<script>
import Header from "../Layout/Header";
import PageTitle from "../Contact/PageTitle";
import ContactContent from "../Contact/ContactContent";
import Footer from "../Layout/Footer";

export default {
  name: "ContactPage",
  components: {
    Header,
    PageTitle,
    ContactContent,
    Footer,
  },
};
</script>
