<template>
    <div class="pt-100">
        <OurPopularProducts />
    </div>
</template>

<script>
import OurPopularProducts from '../Common/OurPopularProducts'

export default {
    name: 'PopularProducts',
    components: {
        OurPopularProducts
    }
}
</script>