<template>
    <div class="pricing-area ptb-100 pb-70">
        <div class="container">
            <div class="section-title white-title">
                <span>Price</span>
                <h2>Our Pricing Plan</h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos tenetur vero deserunt! Magni, laborum. Molestias, quidem mollitia, quae minus vero vel reprehenderit, aliquid alias delectus autem.</p>
            </div>

            <div class="row">
                <div class="col-lg-4 col-sm-6">
                    <div class="single-pricing">
                        <div class="pric-logo">
                            <i class="flaticon-broom"></i>
                        </div>
                        <span>Property</span>
                        <h2>$100</h2>
                        <p>One Time Installation</p>

                        <ul>
                            <li>
                                <i class="flaticon-tick"></i>
                                5 Room Cleaning
                            </li>
                            <li>
                                <i class="flaticon-tick"></i>
                                2 Bathroom Cleaning
                            </li>
                            <li>
                                <i class="flaticon-tick"></i>
                                Window Cleaning
                            </li>
                            <li>
                                <i class="flaticon-tick"></i>
                                Floor  Cleaning
                            </li>
                            <li>
                                <i class="flaticon-tick"></i>
                                Vacuuming
                            </li>
                        </ul>

                        <router-link to="/contact" class="default-btn">
                            Submit Request
                        </router-link>
                    </div>
                </div>

                <div class="col-lg-4 col-sm-6">
                    <div class="single-pricing active">
                        <div class="pric-logo">
                            <i class="flaticon-work"></i>
                        </div>
                        <span>Office</span>
                        <h2>$125</h2>
                        <p>One Time Installation</p>

                        <ul>
                            <li>
                                <i class="flaticon-tick"></i>
                                5 Room Cleaning
                            </li>
                            <li>
                                <i class="flaticon-tick"></i>
                                2 Bathroom Cleaning
                            </li>
                            <li>
                                <i class="flaticon-tick"></i>
                                Window Cleaning
                            </li>
                            <li>
                                <i class="flaticon-tick"></i>
                                Floor  Cleaning
                            </li>
                            <li>
                                <i class="flaticon-tick"></i>
                                Vacuuming
                            </li>
                        </ul>

                        <router-link to="/contact" class="default-btn">
                            Submit Request
                        </router-link>
                    </div>
                </div>

                <div class="col-lg-4 col-sm-6 offset-sm-3 offset-lg-0">
                    <div class="single-pricing">
                        <div class="pric-logo">
                            <i class="flaticon-mop"></i>
                        </div>
                        <span>Park & Road</span>
                        <h2>$199</h2>
                        <p>One Time Installation</p>

                        <ul>
                            <li>
                                <i class="flaticon-tick"></i>
                                5 Room Cleaning
                            </li>
                            <li>
                                <i class="flaticon-tick"></i>
                                2 Bathroom Cleaning
                            </li>
                            <li>
                                <i class="flaticon-tick"></i>
                                Window Cleaning
                            </li>
                            <li>
                                <i class="flaticon-tick"></i>
                                Floor Cleaning
                            </li>
                            <li>
                                <i class="flaticon-tick"></i>
                                Vacuuming
                            </li>
                        </ul>

                        <router-link to="/contact" class="default-btn">
                            Submit Request
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'OurPricingPlan'
}
</script>