<template>
    <div>
        <div class="main-banner-area">
            <div class="d-table">
                <div class="d-table-cell">
                    <div class="container">
                        <div class="row align-items-center">
                            <div class="col-lg-9">
                                <div class="banner-text">
                                    <h1 class="wow fadeInUp" data-wow-delay=".2s">We Provide COVID 19 Disinfecting Services</h1>
                                        
                                    <p class="wow fadeInUp" data-wow-delay=".3s">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nihil architecto laborum eaque! Deserunt maxime, minus quas molestiae reiciendis esse natus nisi iure.</p>

                                    <div class="banner-btn wow fadeInUp" data-wow-delay=".4s">
                                        <router-link class="default-btn" to="/contact">
                                            Contact Us
                                        </router-link>
                                        <router-link class="default-btn active" to="/about">
                                            Learn More
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                            
                            <div class="col-lg-3">
                                <div class="video-btn-animat one">
                                    <div
                                        class="video-btn popup-youtube"
                                        v-for="(image, imageIndex) in items"
                                        :key="imageIndex"
                                        @click="index = imageIndex"
                                        style="cursor: pointer"
                                    >
                                        <i class="bx bx-play"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'MainBanner',
}
</script>