<template>
    <div class="about-area-two bg-color pt-100 pb-100">
        <div class="container">
            <div class="row align-items-top">
                <div class="col-lg-6">
                    <div class="about-img-two">
                        <img src="../../assets/images/renaissance/home/renaissance_in_action_2.jpg" alt="Renaissance in action.">

                        <p class="block-p page-mb">
                            We subscribe and committed to the following.
                        </p>
                    </div>
                </div>

                <div class="col-lg-6">
                    <div class="faq-accordion">
                        <accordion>
                            <accordion-item v-for="value in values" :key="value.title"> 
                                <template v-slot:accordion-trigger>
                                    <button class="accordion-title">
                                        <i class='bx bx-chevron-down'></i> 
                                        {{value.title}}
                                    </button>
                                </template>
                                <template v-slot:accordion-content>
                                    <p>{{value.description}}</p>
                                </template>
                            </accordion-item>
                        </accordion>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Accordion from "../Common/Accordion";
import AccordionItem from "../Common/AccordionItem";
export default {
    name: 'AboutUsContentTwo',
    components: {
        Accordion,
        AccordionItem
    },
    data: () => ({
        values: [
            {title: 'VISION', description: 'Renaissance cleaning aims to be rated the leading office cleaning business in South Africa.'},
            {title: 'MISSION', description: 'Renaissance Cleaning aims to be a successful, World class provider of office cleaning services, while behaving in a socially responsible and progressive manner.'},
            {title: 'CUSTOMER SERVICE', description: 'Providing quality and value to satisfy the requirements of all our customers.'},
            {title: 'PRODUCT QUALITY', description: 'Providing service of uncompromising quality to meet the needs of our customers.'},
            {title: 'COMMUNICATION', description: 'Engage in open, honest communication and welcome freedom of expression.'},
            {title: 'RESPECT, DIGNITY & EQUAL OPPORTUNITY', description: 'It is important to us to treat each other with trust and respect, & to ensure fair treatment & equal opportunity.'},
            {title: 'SAFE AND HEALTHY WORK ENVIRONMENT', description: 'Employees will work in an environment, which is both safe and healthy.'}
        ]
    })
}
</script>