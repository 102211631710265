<template>
    <div class="product-area ptb-100">
        <div class="container">
            <div class="woocommerce-topbar">
                <div class="row align-items-center">
                    <div class="col-lg-8 col-md-7 col-sm-6">
                        <div class="woocommerce-result-count">
                            <p>Showing 1-8 of 14 results</p>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-5 col-sm-6">
                        <div class="woocommerce-topbar-ordering">
                            <select>
                                <option value="1">Default sorting</option>
                                <option value="2">Antiseptics</option>
                                <option value="0">Medical Gloves</option>
                                <option value="3">Drugs</option>
                                <option value="4">Aspirin</option>
                                <option value="5">Hand Sanitizer</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <ShopItem 
                    v-for="product in products" 
                    :item="product" 
                    :key="product.id" 
                    @update-cart="updateCart"
                    class="col-lg-4 col-md-6 col-sm-6"
                />
                
                <div class="col-lg-12">
                    <div class="page-navigation-area">
                        <nav>
                            <ul class="pagination">
                                <li class="page-item">
                                    <a class="page-link page-links" href="#">
                                        <i class='bx bx-chevrons-left'></i>
                                    </a>
                                </li>
                                <li class="page-item active">
                                    <a class="page-link" href="#">1</a>
                                </li>
                                <li class="page-item">
                                    <a class="page-link" href="#">2</a>
                                </li>
                                <li class="page-item">
                                    <a class="page-link" href="#">3</a>
                                </li>
                                <li class="page-item">
                                    <a class="page-link" href="#">
                                        <i class='bx bx-chevrons-right'></i>
                                    </a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ShopItem from './ShopItem'

export default {
    name: 'ShopCard',
    components: {
        ShopItem
    },
    methods: {
        updateCart(e) {
            this.cart.push(e);
            this.total = this.shoppingCartTotal;
        },
        emptyCart(){
            this.cart = [];
            this.total = 0;
        }
    },
    computed: {
        products(){
            return this.$store.state.items.filter(item => {
                return item.productsType === 'shop'
            })
        }
    },
}
</script>