<template>
    <header class="header-area fixed-top">
        <TopHeader />
        <Navbar />
    </header>
</template>

<script>
    import TopHeader from '../Layout/TopHeader'
    import Navbar from '../Layout/Navbar'

export default {
    name: 'Header',
    components: {
        TopHeader,
        Navbar
    }
}
</script>