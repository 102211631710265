<template>
    <div class="prevent-area-two pt-100 pb-100">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6">
                    <div class="prevent-content-two">
                        <span>Prevent</span>
                        <h2>Protect Yourself from Virus</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>

                        <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Architecto cupiditate eius laboriosam doloremque, accusamus ex adipisci, accusantium incidunt illo ratione, tempore eum ipsum explicabo aliquam iusto sit! Nostrum, at commodi.</p>

                        <router-link to="/contact" class="default-btn">
                            Get Quote
                        </router-link>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="prevent-img-two">
                        <img src="../../assets/images/prevent-img-two.jpg" alt="Image">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'ProtectYourselfFromVirus'
}
</script>