<template>
    <div>
        <Header />
        <PageTitle />
        <OurRecentProjects />
        <Footer />
    </div>
</template>

<script>
import Header from '../Layout/Header'
import PageTitle from '../Project/PageTitle'
import OurRecentProjects from '../Project/OurRecentProjects'
import Footer from '../Layout/Footer'

export default {
    name: 'ProjectPage',
    components: {
        Header,
        PageTitle,
        OurRecentProjects,
        Footer,
    }
}
</script>
