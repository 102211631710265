<template>
    <div class="services-area-two bg-color ptb-100">
        <div class="container">
            <div class="section-title">
                <span class="top-title">Service</span>
                <h2>Commercial Disinfection Services</h2>
                <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Maxime vero reprehenderit vel accusantium, facilis architecto consectetur nobis tempore ullam suscipit tenetur mollitia corporis veritatis.</p>
            </div>

            <div class="row">
                <div class="col-lg-4 col-sm-6">
                    <div class="single-services">
                        <div class="services-content">
                            <span class="flaticon-couch"></span>
                            <h3>House Cleaning</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt dolor sit amet, consectetur.</p>
                            
                            <router-link to="/services-details" class="read-more">
                                Read More
                                <i class="bx bx-plus"></i>
                            </router-link>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-sm-6">
                    <div class="single-services">
                        <div class="services-content">
                            <span class="flaticon-windows"></span>
                            <h3>Window  Cleaning</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt dolor sit amet, consectetur.</p>

                            <router-link to="/services-details" class="read-more">
                                Read More
                                <i class="bx bx-plus"></i>
                            </router-link>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-sm-6">
                    <div class="single-services">
                        <div class="services-content">
                            <span class="flaticon-house"></span>
                            <h3>Apartment Cleaning</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt dolor sit amet, consectetur.</p>

                            <router-link to="/services-details" class="read-more">
                                Read More
                                <i class="bx bx-plus"></i>
                            </router-link>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-sm-6">
                    <div class="single-services">
                        <div class="services-content">
                            <span class="flaticon-factory"></span>
                            <h3>Industry Cleaning</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt dolor sit amet, consectetur.</p>

                            <router-link to="/services-details" class="read-more">
                                Read More
                                <i class="bx bx-plus"></i>
                            </router-link>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-sm-6">
                    <div class="single-services">
                        <div class="services-content">
                            <span class="flaticon-hospital"></span>
                            <h3>Hospital & Health Care</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt dolor sit amet, consectetur.</p>

                            <router-link to="/services-details" class="read-more">
                                Read More
                                <i class="bx bx-plus"></i>
                            </router-link>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-sm-6">
                    <div class="single-services">
                        <div class="services-content">
                            <span class="flaticon-toilet"></span>
                            <h3>Bathroom Cleaning</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt dolor sit amet, consectetur.</p>

                            <router-link to="/services-details" class="read-more">
                                Read More
                                <i class="bx bx-plus"></i>
                            </router-link>
                        </div>
                    </div>
                </div>

                <div class="col-lg-12">
                    <div class="page-navigation-area">
                        <nav aria-label="Page navigation example text-center">
                            <ul class="pagination">
                                <li class="page-item">
                                    <a class="page-link page-links" href="#">
                                        <i class='bx bx-chevrons-left'></i>
                                    </a>
                                </li>
                                <li class="page-item active">
                                    <a class="page-link" href="#">1</a>
                                </li>
                                <li class="page-item">
                                    <a class="page-link" href="#">2</a>
                                </li>
                                <li class="page-item">
                                    <a class="page-link" href="#">3</a>
                                </li>
                                <li class="page-item">
                                    <a class="page-link" href="#">
                                        <i class='bx bx-chevrons-right'></i>
                                    </a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'CommercialDisinfectionServices'
}
</script>