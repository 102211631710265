<template>
    <div>
        <Header />
        <PageTitle />
        <AboutUsContent />
        <AboutUsContentTwo />
        <VirtualDisnifectionServices />
        <Footer />
    </div>
</template>

<script>
import Header from '../Layout/Header'
import PageTitle from '../About/PageTitle'
import AboutUsContent from '../About/AboutUsContent'
import AboutUsContentTwo from '../About/AboutUsContentTwo'
import VirtualDisnifectionServices from '../About/VirtualDisnifectionServices'
import Footer from '../Layout/Footer'

export default {
    name: 'AboutPage',
    components: {
        Header,
        PageTitle,
        AboutUsContent,
        AboutUsContentTwo,
        VirtualDisnifectionServices,
        Footer
    }
}
</script>
