<template>
    <div class="page-title-area bg-3">
        <div class="container">
            <div class="page-title-content">
                <h2>Testimonials</h2>
                <ul>
                    <li>
                        <router-link to="/">
                            Home 
                        </router-link>
                    </li>
                    <li class="active">Testimonials</li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'PageTitle'
}
</script>