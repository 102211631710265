<template>
    <div class="location-search-area pb-100">
        <div class="container">
            <form class="location-search-wrap">
                <div class="row">
                    <div class="col-lg-4 col-sm-6">
                        <div class="form-group">
                            <input type="text" class="form-control" id="Location" placeholder="Find Your Location">
                            <i class="bx bx-location-plus"></i>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6">
                        <div class="form-group">
                            <select>
                                <option value="">Select Service</option>
                                <option value="">House Cleaning</option>
                                <option value="">Apartment Cleaning</option>
                                <option value="">Industry Cleaning</option>
                                <option value="">Hospital & Health Care</option>
                                <option value="">Bathroom Cleaning</option>
                            </select>	
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="form-group search">
                            <input type="text" class="form-control" id="Search" placeholder="Start Your Search">
                                    
                            <button type="button" class="search-btn">
                                <i class="bx bx-search"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>

export default {
    name: 'LocationSearch'
}
</script>