<template>
    <div class="product-area pb-70">
        <div class="container">
            <div class="section-title">
                <span>Products</span>
                <h2>Our Popular Products</h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos tenetur vero deserunt! Magni, laborum. Molestias, quidem mollitia, quae minus vero vel reprehenderit, aliquid alias delectus autem.</p>
            </div>

            <div class="row justify-content-center">
                <ProductsItem 
                    v-for="product in products" 
                    :item="product" 
                    :key="product.id" 
                    @update-cart="updateCart"
                    class="col-lg-4 col-md-6 col-sm-6"
                />
            </div>
        </div>
    </div>
</template>

<script>
import ProductsItem from './ProductsItem'

export default {
    name: 'OurPopularProducts',
    components: {
        ProductsItem
    },
    methods: {
        updateCart(e) {
            this.cart.push(e);
            this.total = this.shoppingCartTotal;
        },
        emptyCart(){
            this.cart = [];
            this.total = 0;
        }
    },
    computed: {
        products(){
            return this.$store.state.items.filter(item => {
                return item.productsType === 'popular'
            })
        }
    },
}
</script>