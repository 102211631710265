<template>
    <div>
        <Header />
        <PageTitle />
        <ShopCard />
        <Footer />
    </div>
</template>

<script>
import Header from '../Layout/Header'
import PageTitle from '../Shop/PageTitle'
import ShopCard from '../Shop/ShopCard'
import Footer from '../Layout/Footer'

export default {
    name: 'ShopPage',
    components: {
        Header,
        PageTitle,
        ShopCard,
        Footer,
    }
}
</script>