<template>
  <div class="appointment-area ptb-100">
    <div class="container">
      <div class="section-title white-title">
        <span>Call To Action</span>
        <h2>Make An Appointment</h2>
      </div>
      <div class="appointment-here-form">
        <form @submit="submitForm">
          <div class="row">
            <div class="col-lg-4 col-sm-6">
              <div class="form-group">
                <input
                  type="text"
                  class="form-control"
                  id="name"
                  placeholder="Enter Your Name"
                  v-model="contact.name"
                />
                <i class="bx bx-user"></i>
              </div>
            </div>

            <div class="col-lg-4 col-sm-6">
              <div class="form-group">
                <input
                  type="text"
                  class="form-control"
                  id="email"
                  placeholder="Enter Your Email"
                  v-model="contact.email"
                />
                <i class="bx bx-envelope"></i>
              </div>
            </div>

            <div class="col-lg-4 col-sm-6">
              <div class="form-group">
                <input
                  type="text"
                  class="form-control"
                  id="phone"
                  placeholder="Enter Your Phone"
                  v-model="contact.phone"
                />
                <i class="bx bx-mobile-alt"></i>
              </div>
            </div>

            <div class="col-lg-4 col-sm-6">
              <div class="form-group">
                <select id="service" v-model="contact.service">
                  <option value="">Select Service</option>
                  <option value="House Cleaning">House Cleaning</option>
                  <option value="Apartment Cleaning">Apartment Cleaning</option>
                  <option value="Industry Cleaning">Industry Cleaning</option>
                  <option value="Hospital & Health Care">Hospital & Health Care</option>
                  <option value="Bathroom Cleaning">Bathroom Cleaning</option>
                </select>
              </div>
            </div>

            <div class="col-lg-4 col-sm-6">
              <div class="form-group">
                <input
                  type="date"
                  class="form-control"
                  id="date"
                  placeholder="Date"
                  v-model="contact.date"
                />
              </div>
            </div>

            <div class="col-lg-4 col-sm-6">
              <div class="form-group">
                <select id="time" v-model="contact.time">
                  <option value="">Select Time</option>
                  <option value="09.00 AM To 10.00 AM">09.00 AM To 10.00 AM</option>
                  <option value="10.00 AM To 11.00 AM">10.00 AM To 11.00 AM</option>
                  <option value="11.00 AM To 12.00 PM">11.00 AM To 12.00 PM</option>
                  <option value="12.00 PM To 01.00 PM">12.00 PM To 01.00 PM</option>
                  <option value="01.00 PM To 03.00 PM">01.00 PM To 03.00 PM</option>
                  <option value="04.00 PM To 06.00 PM">04.00 PM To 06.00 PM</option>
                  <option value="07.00 PM To 10.00 PM">07.00 PM To 10.00 PM</option>
                </select>
              </div>
            </div>

            <div class="col-12">
              <button class="default-btn">Confirm Appointment</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<style>
.appointment-area {
  background-image: url(../../assets/images/renaissance/home/about_us/renaissance_cleaning_services_13.jpg);
  background-position: top center;
}
</style>
<script>
export default {
  name: "MakeAnAppointment",
  data() {
    return {
      contact: {
        name: "",
        email: "",
        phone: "",
        service: "",
        date: "",
        time: "",
      },
    };
  },

  methods: {
    submitForm(event) {
      event.preventDefault();
      console.log("Form values", this.contact);
    },
  },
};
</script>
