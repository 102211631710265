<template>
    <div class="contact-area ptb-100">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-8">
                    <div class="contact-wrap">
                        <div class="contact-form">
                            <div class="section-title">
                                <h2>Drop Us A Message For Any Query</h2>
                            </div>
                            
                            <form id="contactForm">
                                <div class="row">
                                    <div class="col-lg-6 col-sm-6">
                                        <div class="form-group">
                                            <input type="text" name="name" id="name" class="form-control" required placeholder="Your Name">
                                        </div>
                                    </div>
        
                                    <div class="col-lg-6 col-sm-6">
                                        <div class="form-group">
                                            <input type="email" name="email" id="email" class="form-control" required placeholder="Your Email">
                                        </div>
                                    </div>
        
                                    <div class="col-lg-6 col-sm-6">
                                        <div class="form-group">
                                            <input type="text" name="phone_number" id="phone_number" required class="form-control" placeholder="Your Phone">
                                        </div>
                                    </div>
        
                                    <div class="col-lg-6 col-sm-6">
                                        <div class="form-group">
                                            <input type="text" name="msg_subject" id="msg_subject" class="form-control" required placeholder="Your Subject">
                                        </div>
                                    </div>
        
                                    <div class="col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <textarea name="message" class="form-control" id="message" cols="30" rows="5" required placeholder="Your Message"></textarea>
                                        </div>
                                    </div>
        
                                    <div class="col-lg-12 col-md-12">
                                        <button type="submit" class="default-btn page-btn">
                                            Send Message
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4">
                    <div class="quick-contact">
                        <h3>Quick Contact </h3>

                        <ul>
                            <li>
                                <i class="bx bx-location-plus"></i>
                                Location:
                                <span>71A Main Road, Eastleigh, Edenvale, 1609 </span>
                            </li>

                            <li>
                                <i class="bx bx-phone-call"></i>
                                Call Us:
                                <a href="tel:+27 11 484 0102">+27 11 484 0102</a>
                                <a href="tel:+2783 650 7388">+2783 650 7388</a>
                                <a href="tel:+2783 650 7388">+2783 611 7544</a>
                            </li>

                            <li>
                                <i class="bx bx-envelope"></i>
                                Email Us:
                                <a href="mailto:info@renaissancecs.co.za">
                                    info@renaissancecs.co.za
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'ContactContent'
}
</script>