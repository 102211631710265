<template>
  <div class="team-area ptb-100">
    <div class="container">
      <div class="section-title">
        <span class="top-title">Our Team</span>
        <h2>Meet Our Team</h2>
        <p>
          Our management team comprises leaders who are passionate and equipped with
          relevant skills and proven industry experience
        </p>
      </div>

      <div class="row flex-grow-1">
        <div class="col-lg-4 col-sm-4" v-for="person in persons" :key="person.id">
          <div class="single-team">
            <div class="team-img">
              <img :src="person.image" :alt="person.name" />
              <div class="team-content">
                <h3>{{ person.name }}</h3>
                <span>{{ person.title }}</span>
                <ul class="team-link">
                  <li>
                    <a href="https://www.linkedin.com/">
                      <i class="bx bxl-facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://www.linkedin.com/">
                      <i class="bx bxl-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://www.linkedin.com/">
                      <i class="bx bxl-pinterest-alt"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://www.linkedin.com/">
                      <i class="bx bxl-instagram"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MeetOurTeam",
  data: () => ({
    persons: [
      {
        id: 1,
        name: "Matthew Manzini",
        title: "Managing Director",
        image: require("../../assets/images/team/matthew.jpg"),
      },
      {
        id: 2,
        name: "Engela Manzini",
        title: "Financial Director",
        image: require("../../assets/images/team/engela.jpg"),
      },
      {
        id: 4,
        name: "Lerato Mbonisi",
        title: "Operations Manager",
        image: require("../../assets/images/team/lerato.jpg"),
      },
      {
        id: 5,
        name: "Cardorah Shihundla",
        title: "Operations Manager",
        image: require("../../assets/images/team/cardorah.jpg"),
      },
      {
        id: 6,
        name: "Mike Khoza",
        title: "Project Coordinator",
        image: require("../../assets/images/team/mike.jpg"),
      },
      //   { id: 7, name: "Celumusa Zulu", title: "Senior Dirver" },
    ],
  }),
};
</script>

<style scoped></style>
