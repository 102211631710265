<template>
  <div class="project-area pt-100 pb-70">
    <div class="container-fluid p-0">
      <div class="section-title">
        <span>Clients</span>
        <h2>Our Clients</h2>
        <p></p>
      </div>

      <div class="project-wrap">
        <carousel :autoplay="1000" :settings="settings" :breakpoints="breakpoints">
          <slide v-for="slide in carouselItems" :key="slide.id">
            <div class="single-project">
              <img :src="slide.image" alt="Image" />
            </div>
          </slide>
        </carousel>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { Carousel, Slide, Navigation } from "vue3-carousel";

import "vue3-carousel/dist/carousel.css";

export default defineComponent({
  name: "OurRecentProjects",
  components: {
    Carousel,
    Slide,
    Navigation,
  },
  data: () => ({
    settings: {
      itemsToShow: 4,
      // snapAlign: 'center',
      wrapAround: true,
    },
    carouselItems: [
      {
        id: 1,
        image: require("../../assets/images/renaissance/clients/1.jpg"),
        space: "First National Bank",
      },
      {
        id: 1,
        image: require("../../assets/images/renaissance/clients/2.jpg"),
        space: "First National Bank",
      },
      {
        id: 1,
        image: require("../../assets/images/renaissance/clients/3.jpg"),
        space: "First National Bank",
      },
      {
        id: 1,
        image: require("../../assets/images/renaissance/clients/4.jpg"),
        space: "First National Bank",
      },
      {
        id: 1,
        image: require("../../assets/images/renaissance/clients/5.jpg"),
        space: "First National Bank",
      },
      {
        id: 1,
        image: require("../../assets/images/renaissance/clients/6.jpg"),
        space: "First National Bank",
      },
      {
        id: 1,
        image: require("../../assets/images/renaissance/clients/7.jpg"),
        space: "First National Bank",
      },
      {
        id: 1,
        image: require("../../assets/images/renaissance/clients/8.jpg"),
        space: "First National Bank",
      },
      {
        id: 1,
        image: require("../../assets/images/renaissance/clients/9.jpg"),
        space: "First National Bank",
      },
      {
        id: 1,
        image: require("../../assets/images/renaissance/clients/10.jpg"),
        space: "First National Bank",
      },
      {
        id: 1,
        image: require("../../assets/images/renaissance/clients/11.jpg"),
        space: "First National Bank",
      },
      {
        id: 1,
        image: require("../../assets/images/renaissance/clients/12.jpg"),
        space: "First National Bank",
      },
      {
        id: 1,
        image: require("../../assets/images/renaissance/clients/13.jpg"),
        space: "First National Bank",
      },
      {
        id: 1,
        image: require("../../assets/images/renaissance/clients/14.jpg"),
        space: "First National Bank",
      },
      {
        id: 1,
        image: require("../../assets/images/renaissance/clients/15.jpg"),
        space: "First National Bank",
      },
      {
        id: 1,
        image: require("../../assets/images/renaissance/clients/16.jpg"),
        space: "First National Bank",
      },
      {
        id: 1,
        image: require("../../assets/images/renaissance/clients/17.jpg"),
        space: "First National Bank",
      },
      {
        id: 1,
        image: require("../../assets/images/renaissance/clients/18.jpg"),
        space: "First National Bank",
      },
      {
        id: 1,
        image: require("../../assets/images/renaissance/clients/19.jpg"),
        space: "First National Bank",
      },
      {
        id: 1,
        image: require("../../assets/images/renaissance/clients/20.jpg"),
        space: "First National Bank",
      },
      {
        id: 1,
        image: require("../../assets/images/renaissance/clients/21.jpg"),
        space: "First National Bank",
      },
      {
        id: 1,
        image: require("../../assets/images/renaissance/clients/22.jpg"),
        space: "First National Bank",
      },
      {
        id: 1,
        image: require("../../assets/images/renaissance/clients/23.jpg"),
        space: "First National Bank",
      },
    ],
    breakpoints: {
      0: {
        itemsToShow: 4,
        snapAlign: "center",
      },
      576: {
        itemsToShow: 1,
        snapAlign: "center",
      },
      768: {
        itemsToShow: 1,
        snapAlign: "center",
      },
      992: {
        itemsToShow: 4,
        snapAlign: "center",
      },
      1200: {
        itemsToShow: 4,
        snapAlign: "center",
      },
    },
  }),
});
</script>
