<template>
    <div>
        <Header />
        <PageTitle />
        <RegisterFrom />
        <Footer />
    </div>
</template>

<script>
import Header from '../Layout/Header'
import PageTitle from '../Register/PageTitle'
import RegisterFrom from '../Register/RegisterFrom'
import Footer from '../Layout/Footer'

export default {
    name: 'RegisterPage',
    components: {
        Header,
        PageTitle,
        RegisterFrom,
        Footer,
    }
}
</script>