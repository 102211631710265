<template>
    <div>
        <Header />
        <PageTitle />
        <CommercialDisinfectionServices />
        <Footer />
    </div>
</template>

<script>
import Header from '../Layout/Header'
import PageTitle from '../ServicesTwo/PageTitle'
import CommercialDisinfectionServices from '../ServicesTwo/CommercialDisinfectionServices'
import Footer from '../Layout/Footer'

export default {
    name: 'ServicesPageTwo',
    components: {
        Header,
        PageTitle,
        CommercialDisinfectionServices,
        Footer,
    }
}
</script>