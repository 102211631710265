<template>
    <div>
        <Header />
        <PageTitle /> 
        <MakeAnAppointment />
        <Footer />
    </div>
</template>

<script>
import Header from '../Layout/Header'
import PageTitle from '../Appointment/PageTitle'
import MakeAnAppointment from '../Appointment/MakeAnAppointment'
import Footer from '../Layout/Footer'

export default {
    name: 'AppointmentPage',
    components: {
        Header,
        PageTitle,
        MakeAnAppointment,
        Footer,
    }
}
</script>
