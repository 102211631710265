<template>
    <div>
        <Header />
        <PageTitle />
        <LogInFrom />
        <Footer />
    </div>
</template>

<script>
import Header from '../Layout/Header'
import PageTitle from '../LogIn/PageTitle'
import LogInFrom from '../LogIn/LogInFrom'
import Footer from '../Layout/Footer'

export default {
    name: 'LogInPage',
    components: {
        Header,
        PageTitle,
        LogInFrom,
        Footer,
    }
}
</script>