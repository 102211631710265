<template>
    <div class="services-details-area pt-100 pb-70">
        <div class="container">
            <div class="row">
                <div class="col-lg-4">
                    <div class="services-sidebar">
                        <div class="services-img">
                            <img src="../../assets/images/services/services-1.jpg" alt="Image">

                            <ul>
                                <li>
                                    <a href="https://www.facebook.com/">
                                        <i class="bx bxl-facebook"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.twitter.com/">
                                        <i class="bx bxl-twitter"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.pinterest.com/">
                                        <i class="bx bxl-pinterest-alt"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.instagram.com/">
                                        <i class="bx bxl-instagram"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.youtube.com/">
                                        <i class="bx bxl-youtube"></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div class="availability">
                            <h3>
                                <i class="bx bx-time"></i>
                                Availability
                            </h3>

                            <ul>
                                <li>
                                    Monday - Friday
                                    <span>9.00 - 20.00</span>
                                </li>
                                <li>
                                    Saturday
                                    <span>10.00 - 16.00</span>
                                </li>
                                <li>
                                    Sunday
                                    <span>9.30 - 18.00</span>
                                </li>
                                <li>
                                    Friday
                                    <span>Closed</span>
                                </li>
                            </ul>
                            <router-link to="/appointment" class="default-btn mt-4">
                                Request An Appointment
                            </router-link>
                        </div>
                        <div class="client-area services-feedback">
                            <div class="client-wrap-two">
                                <carousel 
                                    :autoplay="8000"
                                    :settings="settings"
                                >
                                    <slide 
                                        v-for="slide in carouselItems" 
                                        :key="slide.id"
                                    >
                                        <div class="single-client">
                                            <img :src="slide.image" alt="img">

                                            <p>{{slide.description}}</p>

                                            <ul>
                                                <li>
                                                    <i class="bx bxs-star"></i>
                                                </li>
                                                <li>
                                                    <i class="bx bxs-star"></i>
                                                </li>
                                                <li>
                                                    <i class="bx bxs-star"></i>
                                                </li>
                                                <li>
                                                    <i class="bx bxs-star"></i>
                                                </li>
                                                <li>
                                                    <i class="bx bxs-star"></i>
                                                </li>
                                            </ul>	

                                            <h3>{{slide.name}}</h3>
                                            <span>{{slide.position}}</span>

                                            <div class="quate">
                                                <i class="flaticon-right-quotes-symbol"></i>
                                            </div>
                                        </div>
                                    </slide>
                                </carousel>
                            </div>
                        </div>
                    </div>
                </div>
            
                <div class="col-lg-8">
                    <div class="services-detailss">
                        <div class="services-history">
                            <h2>House Cleaning</h2>
                            <span>Commercial Disinfection Services</span>

                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellat, totam! Dicta rerum deserunt itaque. Incidunt in quo architecto eveniet rem facere, necessitatibus, dolorem voluptas deleniti iure fuga magni velit molestiae ipsum dolor sit amet consectetur adipisicing elit. Repellat, totam adipisicing.</p>

                            <div class="row borders">
                                <div class="col-lg-3 pl-0">
                                    <div class="left-title">
                                        <h3>Category</h3>
                                    </div>
                                </div>
                                
                                <div class="col-lg-9">
                                    <div class="right-title">
                                        <ul>
                                            <li>
                                                <i class="bx bxs-hand-right"></i>
                                                House Cleaning
                                            </li>
                                            <li>
                                                <i class="bx bxs-hand-right"></i>
                                                Window Cleaning
                                            </li>
                                            <li>
                                                <i class="bx bxs-hand-right"></i>
                                                Apartment Cleaning
                                            </li>
                                            <li>
                                                <i class="bx bxs-hand-right"></i>
                                                Industry Cleaning
                                            </li>
                                            <li>
                                                <i class="bx bxs-hand-right"></i>
                                                Hospital & Health Care
                                            </li>
                                            <li>
                                                <i class="bx bxs-hand-right"></i>
                                                Bathroom Cleaning
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        
                            <div class="row borders">
                                <div class="col-lg-3 pl-0">
                                    <div class="left-title">
                                        <h3>Specialty</h3>
                                    </div>
                                </div>

                                <div class="col-lg-9">
                                    <div class="right-title">
                                        <ul>
                                            <li>
                                                <i class="bx bxs-hand-right"></i>
                                                5 Room Cleaning
                                            </li>
                                            <li>
                                                <i class="bx bxs-hand-right"></i>
                                                2 Bathroom Cleaning
                                            </li>
                                            <li>
                                                <i class="bx bxs-hand-right"></i>
                                                Window Cleaning
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div class="row borders">
                                <div class="col-lg-3 pl-0">
                                    <div class="left-title">
                                        <h3>Experience</h3>
                                    </div>
                                </div>

                                <div class="col-lg-9">
                                    <div class="right-title">
                                        <ul>
                                            <li>
                                                <i class="bx bxs-hand-right"></i>
                                                25 years of Experience in Medicine
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div class="row borders">
                                <div class="col-lg-3 pl-0">
                                    <div class="left-title">
                                        <h3>Address</h3>
                                    </div>
                                </div>

                                <div class="col-lg-9">
                                    <div class="right-title">
                                        <ul>
                                            <li>
                                                <i class="bx bxs-hand-right"></i>
                                                123, Western Road, Melbourne Australia
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div class="row borders">
                                <div class="col-lg-3 pl-0">
                                    <div class="left-title">
                                        <h3>Phone</h3>
                                    </div>
                                </div>

                                <div class="col-lg-9">
                                    <div class="right-title">
                                        <ul>
                                            <li>
                                                <i class="bx bxs-hand-right"></i>
                                                +61-821-456
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div class="row borders">
                                <div class="col-lg-3 pl-0">
                                    <div class="left-title">
                                        <h3>Email</h3>
                                    </div>
                                </div>

                                <div class="col-lg-9">
                                    <div class="right-title">
                                        <ul>
                                            <li>
                                                <i class="bx bxs-hand-right"></i>
                                                info@renaissancecs.co.za
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div class="row borders">
                                <div class="col-lg-3 pl-0">
                                    <div class="left-title">
                                        <h3>Website</h3>
                                    </div>
                                </div>

                                <div class="col-lg-9">
                                    <div class="right-title">
                                        <ul>
                                            <li>
                                                <i class="bx bxs-hand-right"></i>
                                                <a href="#">www.trise.com</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import { Carousel, Slide } from 'vue3-carousel';

import 'vue3-carousel/dist/carousel.css';

export default defineComponent ({
    name: 'ServicesDetailsContent',
    components: {
        Carousel,
        Slide,   
    },
    data: () => ({
        settings: {
            itemsToShow: 1,
            snapAlign: 'center',
        },
        carouselItems: [
            {
                id: 1,
                image: require('../../assets/images/clients/client-1.jpg'),
                description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem Ipsum is simply dummy text of the printing and Quis suspendisse typesetting',
                name: 'Steven Jony',
                position: 'CEO of Company',
            },
            {
                id: 2,
                image: require('../../assets/images/clients/client-2.jpg'),
                description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem Ipsum is simply dummy text of the printing and Quis suspendisse typesetting',
                name: 'Omit Jacson',
                position: 'Company Founder',
            },
            {
                id: 3,
                image: require('../../assets/images/clients/client-3.jpg'),
                description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem Ipsum is simply dummy text of the printing and Quis suspendisse typesetting',
                name: 'Robert Michael',
                position: 'Company Manager',
            },
            {
                id: 4,
                image: require('../../assets/images/clients/client-4.jpg'),
                description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem Ipsum is simply dummy text of the printing and Quis suspendisse typesetting',
                name: 'Thomas Daniel',
                position: 'Marketing Support',
            },
            {
                id: 5,
                image: require('../../assets/images/clients/client-5.jpg'),
                description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem Ipsum is simply dummy text of the printing and Quis suspendisse typesetting',
                name: 'Dennis Jose',
                position: 'Agent Management',
            },
            {
                id: 6,
                image: require('../../assets/images/clients/client-6.jpg'),
                description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem Ipsum is simply dummy text of the printing and Quis suspendisse typesetting',
                name: 'Christian Terry',
                position: 'Project Manager',
            },
        ],
    }),
})
</script>