<template>
    <div>
        <Header />
        <HeroSlider />
        <Feature />
        <AboutUsContent />
        <MakeAnAppointment />
        <OurRecentProjects/>
        <Footer />
    </div>
</template>

<script>
import Header from '../Layout/Header'
import HeroSlider from '../HomeOne/HeroSlider'
import Feature from '../HomeOne/Feature'
import AboutUsContent from '../HomeOne/AboutUsContent'
import MakeAnAppointment from '../Common/MakeAnAppointment'
import Footer from '../Layout/Footer'
import OurRecentProjects from '../../components/HomeOne/OurRecentProjects.vue'

export default {
    name: 'HomePageOne',
    components: {
        Header,
        HeroSlider,
        Feature,
        AboutUsContent,
        OurRecentProjects,
        MakeAnAppointment,
        Footer,
    }
}
</script>
