<template>
    <div class="important-area">
        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-6 ptb-100">
                    <div class="important-content">
                        <span>On Record</span>
                        <h2>Renaissance Cleaning Services</h2>
                        <p>We offer an innovative approach to Facilities Optimization Management to provide you with smart, effective and tailored cleaning solutions.</p>
                        <router-link to="/contact" class="default-btn">
                            Get Quote
                        </router-link>
                    </div>
                </div>
                
                <div class="col-lg-6 pr-0">
                    <div class="important-img">
                        <div class="counter-wrap">
                            <div class="row">
                                <div class="col-lg-6 col-sm-6">
                                    <div class="single-counter">
                                        <h2>20+</h2>
                                        <p>Years Of Experiences</p>
                                    </div>
                                </div>
            
                                <div class="col-lg-6 col-sm-6">
                                    <div class="single-counter">
                                        <h2>3500+</h2>
                                        <p>Happy Customer</p>
                                    </div>
                                </div>
            
                                <div class="col-lg-6 col-sm-6">
                                    <div class="single-counter mb-0">
                                        <h2>25+</h2>
                                        <p>Successful Project</p>
                                    </div>
                                </div>
            
                                <div class="col-lg-6 col-sm-6">
                                    <div class="single-counter mb-0">
                                        <h2>50+</h2>
                                        <p>Expert Stuff</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'VirtualDisnifectionServices'
}
</script>