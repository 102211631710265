<template>
    <div>
        <Header />
        <PageTitle /> 
        <TermsConditionsContent />
        <Footer />
    </div>
</template>

<script>
import Header from '../Layout/Header'
import PageTitle from '../TermsConditions/PageTitle'
import TermsConditionsContent from '../TermsConditions/TermsConditionsContent'
import Footer from '../Layout/Footer'

export default {
    name: 'TermsConditionsPage',
    components: {
        Header,
        PageTitle,
        TermsConditionsContent,
        Footer,
    }
}
</script>