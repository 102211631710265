<template>
    <div class="feature-three pb-70">
        <div class="container">
            <div class="section-title">
                <span>Our Feature</span>
                <h2>Your Comfort Depends on Us</h2>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. A facilis vel consequatur tempora atque blanditiis exercitationem incidunt, alias corporis quam assumenda dicta, temporibus.</p>
            </div>

            <div class="row">
                <div class="col-lg-4 col-sm-6">
                    <div class="single-feature-three">
                        <span class="flaticon-broom"></span>
                        <h3>Professional Cleaning</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore dolore</p>
                    </div>
                </div>

                <div class="col-lg-4 col-sm-6">
                    <div class="single-feature-three">
                        <span class="flaticon-premium-quality"></span>
                        <h3>High Quality</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore dolore</p>
                    </div>
                </div>

                <div class="col-lg-4 col-sm-6 offset-sm-3 offset-lg-0">
                    <div class="single-feature-three">
                        <span class="flaticon-hand-wash-1"></span>
                        <h3>Quick Cleaning</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore dolore</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'YourComfortDependsOnUs'
}
</script>