<template>
    <div>
        <Header />
        <PageTitle />
        <WhatOurClientsSay />
        <Footer />
    </div>
</template>

<script>
import Header from '../Layout/Header'
import PageTitle from '../Testimonials/PageTitle'
import WhatOurClientsSay from '../Testimonials/WhatOurClientsSay'
import Footer from '../Layout/Footer'

export default {
    name: 'TestimonialsPage',
    components: {
        Header,
        PageTitle,
        WhatOurClientsSay,
        Footer,
    }
}
</script>
