<template>
  <div>
    <div class="hero-slider-area">
      <div class="hero-slider-wrap">
        <carousel :autoplay="8000" :settings="settings">
          <slide v-for="slide in carouselItems" :key="slide.id">
            <div :class="['slider-item', slide.class]">
              <div class="d-table">
                <div class="d-table-cell">
                  <div class="container">
                    <div class="row align-items-center">
                      <div class="col-lg-9">
                        <div class="slider-text one">
                          <h1>{{ slide.heading }}</h1>
                          <p>{{ slide.description }}</p>

                          <div class="slider-btn">
                            <router-link class="default-btn" to="/contact">
                              Contact Us
                            </router-link>
                            <router-link class="default-btn active" to="/services">
                              Read More
                            </router-link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </slide>

          <template #addons>
            <navigation>
              <template #prev>
                <i class="bx bx-chevrons-left"></i>
              </template>
              <template #next>
                <i class="bx bx-chevrons-right"></i>
              </template>
            </navigation>
          </template>
        </carousel>
      </div>
      <div class="shape">
        <img src="../../assets/images/home-slider.png" alt="Image" />
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { Carousel, Slide, Navigation } from "vue3-carousel";

import "vue3-carousel/dist/carousel.css";

export default defineComponent({
  name: "HeroSlider",
  components: {
    Carousel,
    Slide,
    Navigation,
  },
  data: () => ({
    isPopup: false,
    settings: {
      itemsToShow: 1,
      snapAlign: "center",
    },
    carouselItems: [
      {
        id: 1,
        class: "slider-item-bg-1",
        heading: "Renaissance Cleaning Services",
        description:
          "We offer contract cleaning services for small offices right through to prestigious Head Offices, multi-site buildings and other spaces.",
      },
      {
        id: 2,
        class: "slider-item-bg-2",
        heading: "Disinfecting Your Area",
        description: "We provide effective disinfectant services and infection control.",
      },
    ],
  }),
  methods: {
    isPopupMethod(isPopup) {
      return (this.isPopup = !isPopup);
    },
  },
});
</script>
