<template>
    <div>
        <Header />
        <PageTitle />
        <ServicesContentOne />
        <ServicesContentTwo />
        <HowItWorks />
        <Footer />
    </div>
</template>

<script>
import Header from '../Layout/Header'
import PageTitle from '../ServicesThree/PageTitle'
import ServicesContentOne from '../ServicesThree/ServicesContentOne'
import ServicesContentTwo from '../ServicesThree/ServicesContentTwo'
import HowItWorks from '../ServicesThree/HowItWorks'
import Footer from '../Layout/Footer'

export default {
    name: 'ServicesPageThree',
    components: {
        Header,
        PageTitle,
        ServicesContentOne,
        ServicesContentTwo,
        HowItWorks,
        Footer,
    }
}
</script>