<template>
    <div>
        <Header />
        <PageTitle />
        <BlogContent />
        <Footer />
    </div>
</template>

<script>
import Header from '../Layout/Header'
import PageTitle from '../BlogGrid/PageTitle'
import BlogContent from '../BlogGrid/BlogContent'
import Footer from '../Layout/Footer'

export default {
    name: 'BlogGridPage',
    components: {
        Header,
        PageTitle,
        BlogContent,
        Footer,
    }
}
</script>