<template>
    <div>
        <Header />
        <PageTitle />
        <BlogLeftSidebarContent />
        <Footer />
    </div>
</template>

<script>
import Header from '../Layout/Header'
import PageTitle from '../BlogLeftSidebar/PageTitle'
import BlogLeftSidebarContent from '../BlogLeftSidebar/BlogLeftSidebarContent'
import Footer from '../Layout/Footer'

export default {
    name: 'BlogLeftSidebarPage',
    components: {
        Header,
        PageTitle,
        BlogLeftSidebarContent,
        Footer,
    }
}
</script>