<template>
    <div class="about-area-two pb-100">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6">
                    <div class="about-content-two">
                        <span>About Us</span>
                        <h2>We Offer a Wide Range of Disinfecting Solutions</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>
                        
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Accusantium, facere necessitatibus. Dignissimos saepe molestias maiores officia veniam! Eveniet harum vel voluptas eius saepe unde molestiae doloribus! Cum facilis nesciunt eius!</p>

                        <ul>
                            <li>
                                <i class="flaticon-checked-1"></i>
                                Deep Cleaning Service
                            </li>
                            <li>
                                <i class="flaticon-checked-1"></i>
                                Disinfecting  Services
                            </li>
                            <li>
                                <i class="flaticon-checked-1"></i>
                                COVID-19 Disinfecting 
                            </li>
                            <li>
                                <i class="flaticon-checked-1"></i>
                                Virucidal Disinfectants
                            </li>
                        </ul>

                        <router-link to="/about" class="default-btn">
                            Learn More
                        </router-link>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="about-img-two">
                        <img src="../../assets/images/about-img-two.jpg" alt="Image">
                        <p class="block-p">
                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas expedita, illum debitis ex sed culpa perferendis.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'AboutUsContent'
}
</script>