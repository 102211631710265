<template>
    <div class="blog-area pt-100 pb-70">
        <div class="container">
            <div class="section-title">
                <span class="top-title">News</span>
                <h2>Our Latest News</h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus</p>
            </div>

            <div class="row">
                <div class="col-lg-4 col-md-6">
                    <div class="single-blog">
                        <router-link to="/blog-details">
                            <img src="../../assets/images/blog/blog-1.jpg" alt="Image">
                        </router-link>
                    
                        <div class="blog-content">
                            <ul>
                                <li>
                                    <router-link to="/blog-grid">Admin</router-link>
                                </li>
                                <li>
                                    17 / 05 / 2022
                                </li>
                            </ul>

                            <router-link to="/blog-details">
                                <h3>Corona Virus Infection Prevention</h3>
                            </router-link>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt amet.</p>

                            <router-link to="/blog-details" class="read-more">
                                Read More
                                <i class="bx bx-plus"></i>
                            </router-link>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="single-blog">
                        <router-link to="/blog-details">
                            <img src="../../assets/images/blog/blog-2.jpg" alt="Image">
                        </router-link>
                        
                        <div class="blog-content">
                            <ul>
                                <li>
                                    <router-link to="/blog-grid">Admin</router-link>
                                </li>
                                <li>
                                    18 / 05 / 2022
                                </li>
                            </ul>

                            <router-link to="/blog-details">
                                <h3>Superior Clean Through Our Trained</h3>
                            </router-link>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt amet.</p>

                            <router-link to="/blog-details" class="read-more">
                                Read More
                                <i class="bx bx-plus"></i>
                            </router-link>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                    <div class="single-blog">
                        <router-link to="/blog-details">
                            <img src="../../assets/images/blog/blog-3.jpg" alt="Image">
                        </router-link>
                        
                        <div class="blog-content">
                            <ul>
                                <li>
                                    <router-link to="/blog-grid">Admin</router-link>
                                </li>
                                <li>
                                    19 / 05 / 2022
                                </li>
                            </ul>
                        
                            <router-link to="/blog-details">
                                <h3>How To Clean And Spread Disinfect</h3>
                            </router-link>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt amet.</p>

                            <router-link to="/blog-details" class="read-more">
                                Read More
                                <i class="bx bx-plus"></i>
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'OurLatestNews'
}
</script>