<template>
    <div class="feature-area pb-100">
        <div class="container">
            <div class="row">
                <div class="col-lg-9">
                    <div class="feature-wrap">
                        <div class="row">
                            <div class="col-lg-4 col-sm-6">
                                <div class="single-feature">
                                    <i class="flaticon-satisfaction"></i>
                                    <h3>Satisfaction</h3>
                                    <p>We put the utmost care and attention.</p>
                                </div>
                            </div>

                            <div class="col-lg-4 col-sm-6">
                                <div class="single-feature">
                                    <i class="flaticon-cleaning"></i>
                                    <h3>Expert Cleaner</h3>
                                    <p>Superior cleaning makes a tremendous difference.</p>
                                </div>
                            </div>

                            <div class="col-lg-4 col-sm-6 offset-sm-3 offset-lg-0">
                                <div class="single-feature">
                                    <i class="flaticon-support"></i>
                                    <h3>24/7 Support</h3>
                                    <p>We are at your service.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div class="col-lg-3">
                    <div class="hot-line">
                        <span>Hot Line</span>
                        <h3><i class="bx bx-phone-call"></i> Contact Us Today!</h3>
                        <a href="tel:+27 11 484 0102">+27 11 484 0102</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'Feature'
}
</script>