<template>
    <div class="faq-area bg-2 ptb-100">
        <div class="container">
            <div class="section-title">
                <span class="top-title">FAQ,s</span>
                <h2>Frequently Asked  Questions</h2>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. A facilis vel consequatur tempora atque blanditiis exercitationem incidunt, alias corporis quam assumenda dicta, temporibus.</p>
            </div>

            <div class="row align-items-center">
                <div class="col-lg-6">
                    <div class="faq-img">
                        <img src="../../assets/images/faq-img.png" alt="Image">
                    </div>
                </div>
                
                <div class="col-lg-6">
                    <div class="faq-accordion">
                        <accordion>
                            <accordion-item>
                                <template v-slot:accordion-trigger>
                                    <button class="accordion-title">
                                        <i class='bx bx-chevron-down'></i> 
                                         What is coronavirus? What is COVID-19? 
                                    </button>
                                </template>
                                <template v-slot:accordion-content>
                                    <p>Lorem, ipsum dolor sit amet How do you Startup? consectetur adipisicing elit. Accusamus ipsa error, excepturi, obcaecati aliquid veniam blanditiis quas voluptates maxime unde, iste minima dolores dolor perferendis facilis. How do you Startup blanditiis voluptates.</p>
                                </template>
                            </accordion-item>
                            <accordion-item>
                                <template v-slot:accordion-trigger>
                                    <button class="accordion-title">
                                        <i class='bx bx-chevron-down'></i> 
                                         What are the symptoms of COVID-19? 
                                    </button>
                                </template>
                                <template v-slot:accordion-content>
                                    <p>Lorem, ipsum dolor sit amet How do you Startup? consectetur adipisicing elit. Accusamus ipsa error, excepturi, obcaecati aliquid veniam blanditiis quas voluptates maxime unde, iste minima dolores dolor perferendis facilis. How do you Startup blanditiis voluptates.</p>
                                </template>
                            </accordion-item>
                            <accordion-item>
                                <template v-slot:accordion-trigger>
                                    <button class="accordion-title">
                                        <i class='bx bx-chevron-down'></i> 
                                         What can I do to prevent myself from coronavirus and others? 
                                    </button>
                                </template>
                                <template v-slot:accordion-content>
                                    <p>Lorem, ipsum dolor sit amet How do you Startup? consectetur adipisicing elit. Accusamus ipsa error, excepturi, obcaecati aliquid veniam blanditiis quas voluptates maxime unde, iste minima dolores dolor perferendis facilis. How do you Startup blanditiis voluptates.</p>
                                </template>
                            </accordion-item>
                            <accordion-item>
                                <template v-slot:accordion-trigger>
                                    <button class="accordion-title">
                                        <i class='bx bx-chevron-down'></i> 
                                         What should I do If I feel sick? 
                                    </button>
                                </template>
                                <template v-slot:accordion-content>
                                    <p>Lorem, ipsum dolor sit amet How do you Startup? consectetur adipisicing elit. Accusamus ipsa error, excepturi, obcaecati aliquid veniam blanditiis quas voluptates maxime unde, iste minima dolores dolor perferendis facilis. How do you Startup blanditiis voluptates.</p>
                                </template>
                            </accordion-item>
                            <accordion-item>
                                <template v-slot:accordion-trigger>
                                    <button class="accordion-title">
                                        <i class='bx bx-chevron-down'></i> 
                                         If a loved one gets sick, how can I care for them? 
                                    </button>
                                </template>
                                <template v-slot:accordion-content>
                                    <p>Lorem, ipsum dolor sit amet How do you Startup? consectetur adipisicing elit. Accusamus ipsa error, excepturi, obcaecati aliquid veniam blanditiis quas voluptates maxime unde, iste minima dolores dolor perferendis facilis. How do you Startup blanditiis voluptates.</p>
                                </template>
                            </accordion-item>
                        </accordion>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Accordion from "../Common/Accordion";
import AccordionItem from "../Common/AccordionItem";

export default {
    name: 'Faq',
    components: {
        Accordion,
        AccordionItem
    }
}
</script>