<template>
    <div>
        <Header />
        <PageTitle /> 
        <FrequentlyAskedQuestions />
        <AskQuestions />
        <Footer />
    </div>
</template>

<script>
import Header from '../Layout/Header'
import PageTitle from '../Faq/PageTitle'
import FrequentlyAskedQuestions from '../Faq/FrequentlyAskedQuestions'
import AskQuestions from '../Faq/AskQuestions'
import Footer from '../Layout/Footer'

export default {
    name: 'FaqPage',
    components: {
        Header,
        PageTitle,
        FrequentlyAskedQuestions,
        AskQuestions,
        Footer,
    }
}
</script>

