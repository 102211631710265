<template>
    <div class="get-offer pb-100">
        <div class="container">
            <div class="get-offer-wrap">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-6">
                        <div class="get-offer-content">
                            <h3>Get 20% Off</h3>
                            <h3 class="color">Save Money Save Time</h3>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <div class="get-offer">
                            <router-link to="/contact" class="default-btn">
                                Get A Quote
                            </router-link>
                            <router-link to="/appointment" class="default-btn active">
                                Appointment 
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'SaveMoneySaveTime'
}
</script>