<template>
    <div class="important-area">
        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-6 ptb-100">
                    <div class="important-content">
                        <span>Services</span>
                        <h2>Virtual Disnifection Services</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>

                        <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Architecto cupiditate eius laboriosam doloremque, accusamus ex adipisci, accusantium incidunt illo ratione, tempore eum ipsum explicabo aliquam iusto sit! Nostrum, at commodi.</p>

                        <router-link to="/contact" class="default-btn">
                            Get Quote
                        </router-link>
                    </div>
                </div>
                <div class="col-lg-6 pr-0">
                    <div class="important-img">
                        <div class="counter-wrap">
                            <div class="row">
                                <div class="col-lg-6 col-sm-6">
                                    <div class="single-counter">
                                        <h2>
                                            25 
                                            <span class="target">+</span>
                                        </h2>
            
                                        <p>Year</p>
                                    </div>
                                </div>
            
                                <div class="col-lg-6 col-sm-6">
                                    <div class="single-counter">
                                        <h2>
                                            3584
                                            <span class="target">+</span>
                                        </h2>
            
                                        <p>Happy Customer</p>
                                    </div>
                                </div>
            
                                <div class="col-lg-6 col-sm-6">
                                    <div class="single-counter mb-0">
                                        <h2>
                                            258 
                                            <span class="traget">+</span>
                                        </h2>
            
                                        <p>Successful Project</p>
                                    </div>
                                </div>
            
                                <div class="col-lg-6 col-sm-6">
                                    <div class="single-counter mb-0">
                                        <h2>
                                            500+
                                            <span class="traget">+</span>
                                        </h2>
            
                                        <p>Expert Stuff</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'VirtualDisnifectionServices'
}
</script>