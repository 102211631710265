<template>
    <div class="widget-area" id="secondary">
        <div class="widget widget_search">
            <h3 class="widget-title">Search Now</h3>
            <div class="post-wrap">
                <form class="search-form">
                    <label>
                        <span class="screen-reader-text">Search for:</span>
                        <input type="search" class="search-field" placeholder="Search...">
                    </label>
                    <button type="submit"><i class='bx bx-search'></i></button>
                </form>
            </div>
        </div>

        <div class="widget widget-peru-posts-thumb">
            <h3 class="widget-title">Popular Posts</h3>
            <div class="post-wrap">
                <div class="item">
                    <router-link to="/blog-details" class="thumb">
                        <span class="fullimage cover bg1" role="img"></span>
                    </router-link>
                    <div class="info">
                        <time datetime="2022-06-30">April 20, 2022</time>
                        <h4 class="title usmall">
                            <router-link to="/blog-details">
                                How To Clean And Spread Disinfect
                            </router-link>
                        </h4>
                    </div>

                    <div class="clear"></div>
                </div>

                <div class="item">
                    <router-link to="/blog-details" class="thumb">
                        <span class="fullimage cover bg2" role="img"></span>
                    </router-link>
                    <div class="info">
                        <time datetime="2022-06-30">April 21, 2022</time>
                        <h4 class="title usmall">
                            <router-link to="/blog-details">
                                What Can I Do To Prevent Myself & prevent Disease
                            </router-link>
                        </h4>
                    </div>

                    <div class="clear"></div>
                </div>

                <div class="item">
                    <router-link to="/blog-details" class="thumb">
                        <span class="fullimage cover bg3" role="img"></span>
                    </router-link>
                    <div class="info">
                        <time datetime="2022-06-30">April  22, 2022</time>
                        <h4 class="title usmall">
                            <router-link to="/blog-details">
                                Superior Clean Through Our Trained
                            </router-link> 
                        </h4>
                    </div>

                    <div class="clear"></div>
                </div>
                
                <div class="item">
                    <router-link to="/blog-details" class="thumb">
                        <span class="fullimage cover bg4" role="img"></span>
                    </router-link>
                    <div class="info">
                        <time datetime="2022-06-30">April 23, 2022</time>
                        <h4 class="title usmall">
                            <router-link to="/blog-details">
                                Corona Virus Infection Prevention
                            </router-link>
                        </h4>
                    </div>

                    <div class="clear"></div>
                </div>
            </div>
        </div>

        <div class="widget widget_tag_cloud">
            <h3 class="widget-title">Tags</h3>
            <div class="post-wrap">
                <div class="tagcloud">
                    <router-link to="/blog-grid">Antibiotic (3)</router-link>
                    <router-link to="/blog-grid">Diseases (3)</router-link>
                    <router-link to="/blog-grid">Heart (2)</router-link>
                    <router-link to="/blog-grid">Health (2)</router-link>
                    <router-link to="/blog-grid">Hospital (1)</router-link>
                    <router-link to="/blog-grid">Infectious </router-link>
                </div>
            </div>
        </div>       
    </div>
</template>

<script>
export default {
    name: 'BlogSidebar'
}
</script>