<template>
    <div>
        <div class="single-product-box">
            <div class="product-image">
                <router-link to="/product-details">
                    <img :src="item.image" alt="image">
                </router-link>

                <a 
                    href="javascript:void(0)"
                    @click="addToCart(item)"
                    class="add-to-cart-btn"
                >
                    Add To Cart
                </a>

                <div v-if="item.sale" class="sale-btn">Sale!</div>
            </div>

            <div class="product-content">
                <h3><router-link to="/product-details">{{item.name}}</router-link></h3>
                <div class="price">
                    <span class="new">${{item.price}}</span>
                    <span class="old">${{item.oldPrice}}</span>
                </div>
                <div class="rating">
                    <i class="bx bxs-star"></i>
                    <i class="bx bxs-star"></i>
                    <i class="bx bxs-star"></i>
                    <i class="bx bxs-star"></i>
                    <i class="bx bxs-star"></i>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ShopItem',
    props: ['item'],
    methods: {
        addToCart(item) {
            this.$toast.success('Added to cart', {
                icons: 'shopping_cart',
                theme: "bubble", 
                position: "top-right", 
                timeout : 4000
            });
            if(this.size !== '') {
                this.$store.commit({
                    type: 'addToCart',
                    id: item.id,
                    name: item.name,
                    price: item.price,
                    oldPrice: item.oldPrice,
                    image: item.image,
                    productQuantity: 1,
                    totalPrice: item.price,
                })
            }
        }
    }
}
</script>