import { createStore } from "vuex";

export default createStore({
  // state is the same as what would typically go inside of the data object when using Vue without Vuex.
  state: {
    items: [
      {
        id: "1",
        name: "Antiseptics",
        price: 55.5,
        oldPrice: 60.5,
        sale: false,
        image: require("../assets/images/products/product-4.jpg"),
        productsType: "shop",
      },
      {
        id: "2",
        name: "Medical Gloves",
        price: 54.5,
        oldPrice: 65.5,
        sale: true,
        image: require("../assets/images/products/product-5.jpg"),
        productsType: "shop",
      },
      {
        id: "3",
        name: "Drugs",
        price: 14.5,
        oldPrice: 15.5,
        sale: false,
        image: require("../assets/images/products/product-6.jpg"),
        productsType: "shop",
      },
      {
        id: "4",
        name: "Asprin",
        price: 10.0,
        oldPrice: 20.0,
        sale: false,
        image: require("../assets/images/products/product-7.jpg"),
        productsType: "shop",
      },
      {
        id: "5",
        name: "Sanitizer",
        price: 55.5,
        oldPrice: 65.5,
        sale: false,
        image: require("../assets/images/products/product-8.jpg"),
        productsType: "shop",
      },
      {
        id: "6",
        name: "Hand Sanitizer",
        price: 15.0,
        oldPrice: 20.5,
        sale: false,
        image: require("../assets/images/products/product-9.jpg"),
        productsType: "shop",
      },
      {
        id: "7",
        name: "COVID-19 Protect Mask",
        price: 20,
        oldPrice: 25,
        sale: false,
        image: require("../assets/images/products/product-1.jpg"),
        productsType: "popular",
      },
      {
        id: "8",
        name: "Surgical Hand Gloves",
        price: 25,
        oldPrice: 30,
        sale: false,
        image: require("../assets/images/products/product-2.jpg"),
        productsType: "popular",
      },
      {
        id: "9",
        name: "Cleaning Agent Solve",
        price: 30,
        oldPrice: 35,
        sale: false,
        image: require("../assets/images/products/product-3.jpg"),
        productsType: "popular",
      },
    ],
    cart: [],
  },

  // getters are Vuex's equivalent to computed properties in Vue.
  // functions here will always contain state as a parameter
  getters: {
    total: (state) => {
      if (state.cart.length > 0) {
        return state.cart
          .map((item) => item.totalPrice)
          .reduce((total, amount) => total + amount);
      } else {
        return 0;
      }
    },
  },

  // mutations are essentially functions that update state in some way.
  // You can think of these as kind of being Vuex's equivalent to Vue's methods.
  mutations: {
    addToCart(state, payload) {
      let addedItem = state.items.find((item) => item.id === payload.id);

      let existItem = state.cart.find((item) => item.id == payload.id);

      if (existItem) {
        existItem.productQuantity++;
        existItem.totalPrice += addedItem.price;
      } else {
        return state.cart.push(payload);
      }
      // return state.cart.push(payload);
    },

    removeFromCart(state, item) {
      let index = state.cart.indexOf(item);
      state.cart.splice(index, 1);
    },
  },
  // actions are effectively the functions that get called by your components in order to trigger a mutation.
  actions: {},
});
