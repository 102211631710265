<template>
    <div class="about-area pb-100">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6">
                    <div class="about-content">
                        <span>The Cleaning Doctor</span>
                        <h2>Renaissance Cleaning Services</h2>
                        <p>Renaissance Cleaning Services was established in 1999, we take pride of 23 years of excellent service in the cleaning industry.</p>
                        
                        <ul>
                            <li>
                                <i class="flaticon-checked"></i>
                                Contract cleaners
                            </li>
                            <li>
                                <i class="flaticon-checked"></i>
                                Floor care
                            </li>
                            <li>
                                <i class="flaticon-checked"></i>
                                Maintanance carpet cleaning 
                            </li>
                            <li>
                                <i class="flaticon-checked"></i>
                                Disinfecting
                            </li>
                        </ul>
                        <router-link to="/about" class="default-btn">
                            Read more
                        </router-link>
                    </div>
                </div>
                <div class="col-lg-6">
                    <carousel 
                    :autoplay="1000"
                    :settings="settings"
                    :breakpoints="breakpoints">
                        <slide 
                            v-for="slide in carouselItems" 
                            :key="slide.id">
                            <div class="single-project">
                                <img :src="slide.image" alt="Image">
                            </div>
                        </slide>
                    </carousel>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Carousel, Slide } from 'vue3-carousel';
export default {
    name: 'AboutUsContent',
    components: {
        Carousel,
        Slide   
    },
    data: () => ({
        settings: {
            itemsToShow: 1,
            wrapAround: true,
            transition: 2000
        },
        
        breakpoints: {
            0: {
                itemsToShow: 1,
                snapAlign: 'center',
			},
            576: {
                itemsToShow: 1,
                snapAlign: 'center',
            },
            768: {
                itemsToShow: 1,
                snapAlign: 'center',
            },
            992: {
                itemsToShow: 1,
                snapAlign: 'center',
            },
            1200: {
                itemsToShow: 1,
                snapAlign: 'center',
            },
        },
        carouselItems: [
            {
                id: 1,
                image: require('../../assets/images/renaissance/home/about_us/renaissance_cleaning_services_13.jpg'),
                
            },
            {
                id: 1,
                image: require('../../assets/images/renaissance/home/about_us/renaissance_cleaning_services_12.jpg'),
                
            },
            {
                id: 1,
                image: require('../../assets/images/renaissance/home/about_us/renaissance_cleaning_services_11.jpg'),
                
            },
            {
                id: 1,
                image: require('../../assets/images/renaissance/home/about_us/renaissance_cleaning_services_10.jpg'),
            },
            {
                id: 1,
                image: require('../../assets/images/renaissance/home/about_us/renaissance_cleaning_services_9.jpg'),
                
            },
            {
                id: 1,
                image: require('../../assets/images/renaissance/home/about_us/renaissance_cleaning_services_8.jpg'),
                
            },
            {
                id: 1,
                image: require('../../assets/images/renaissance/home/about_us/renaissance_cleaning_services_7.jpg'),
                
            },
            {
                id: 1,
                image: require('../../assets/images/renaissance/home/about_us/renaissance_cleaning_services_6.jpg'),
                
            },
            {
                id: 1,
                image: require('../../assets/images/renaissance/home/about_us/renaissance_cleaning_services_5.jpg'),
                
            },
            {
                id: 1,
                image: require('../../assets/images/renaissance/home/about_us/renaissance_cleaning_services_4.jpg'),
                
            },
            {
                id: 1,
                image: require('../../assets/images/renaissance/home/about_us/renaissance_cleaning_services_3.jpg'),
                
            },
            {
                id: 1,
                image: require('../../assets/images/renaissance/home/about_us/renaissance_cleaning_services_2.jpg'),
                
            },
            {
                id: 1,
                image: require('../../assets/images/renaissance/home/about_us/renaissance_cleaning_services_1.jpg'),
                
            }
        ],
    }),
}
</script>